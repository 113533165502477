<template>

    <div class="footer-desktop__wrapper has-bottom" v-if="isShow">
        <div :class="{ 'footer-desktop__desc-wrapper': !isMobile }">
            <div
                :class="{ 
                    'container': !isMobile,
                    'footer-mobile__desc-wrapper': isMobile,
                    'display-flex-sb': currentLocale !== 'th' && currentLocale !== 'vn'
                }"
                style="align-items: baseline;"
            >
                <div class="footer-link__wrapper" v-if="currentLocale === 'vn'">
                    <!-- currentLocale === 'th' ||  -->
                    <div class="footer-link" v-for="article in footerLinks" :key="article.id"><router-link :to="{ path: encodeURI(`/${currentLocale}/${article.url}`) }">{{ article.linkText }}</router-link></div>
                </div>
                <div class="footer-desktop__desc-container" v-else-if="currentLocale !== 'th' && currentLocale != 'vn'">
                    <h2 class="footer-desktop__desc-title" v-if="currentLocale === 'th'">{{$t('FOOTER_DESCRIPTION_TITLE')}}</h2>
                     <h2 class="footer-desktop__desc-title" v-else></h2>
                    <p class="footer-desktop__desc-content">{{$t('FOOTER_DESCRIPTION_CONTENT')}}</p>
                </div>  
                <div class="footer-desktop__desc-container" v-if="!isMobile">
                    <h2 class="footer-desktop__desc-title" >{{$t('FOOTER_DISCLAIMER_TITLE')}}</h2>
                    <p class="footer-desktop__desc-content">{{$t('FOOTER_DISCLAIMER_CONTENT')}}</p>
                </div>        
            </div>
        </div>
        <div class="footer-mobile__desc-wrapper " v-if="isMobile">
            <div class="container display-flex-sb" style="align-items: baseline;"> 
                <div class="footer-mobile__desc-container">
                    <h2 class="footer-desktop__desc-title" >{{$t('FOOTER_DISCLAIMER_TITLE')}}</h2>
                    <p class="footer-desktop__desc-content">{{$t('FOOTER_DISCLAIMER_CONTENT')}}</p>
                </div>        
            </div>
        </div>
        <div v-if="isMobile" class="footer-mobile__desc-wrapper footer-mobile__privacy-wrapper">
            <div class="footer-mobile__privacy">
                <router-link :to="{name: 'privacypolicy'}" >{{$t('PRIVACY_POLICY')}}</router-link>
            </div>
            <p class="footer-mobile__copyright">{{$t('COPYRIGHT')}} © {{currentYear}} www.asiasport.com</p>
        </div>

        <div class="footer-desktop__social-wrapper ">
            <div class="container">
                <div class="display-flex-align-center">
                    <MainLogo class="mr-2rem home-logo"></MainLogo>
                    <div class="footer-desktop__social-container">
                        <a :href="$t('FB')" target="_blank" class="footer-desktop__social-icon hover-grow"><img src="../../static/images/icons/icon_fb.png"></a>
                        <a :href="$t('IG')" target="_blank" class="footer-desktop__social-icon hover-grow"><img src="../../static/images/icons/icon_ig.png"></a>
                        <a :href="getTiktokLink()" v-if="currentLocale !== 'en' && isShowTiktok" target="_blank" class="footer-desktop__social-icon hover-grow"><img src="../../static/images/icons/icon_tiktok_black.png"></a>

                        <a href="https://line.me/R/ti/p/@asiasport.thai" target="_blank" v-if="currentLocale === 'th'" class="footer-desktop__social-icon hover-grow"><img src="../../static/images/icons/icon_line.png"></a>
                        <a href="mailto:support@asiasport.com" target="_blank" class="footer-desktop__social-icon hover-grow"><img src="../../static/images/icons/icon_mail.png"></a>
                    </div>          
                    <div class="footer-desktop__copy-right">
                        <p class="font-m">{{$t('COPYRIGHT')}} © {{currentYear}} www.asiasport.com</p>
                    </div> 
                    <div>
                        <router-link :to="{name: 'privacypolicy'}" class="font-m footer-desktop__privacy">{{$t('PRIVACY_POLICY')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-wrapper" v-if="isShow">
        <ul class="footer-container">
            <div class="footer-icon__wrapper" :class="{selected : o.selected}" v-for="(o, index) in footerTabs" :key="index">          
               <div v-if="o.id !== 9" class="relative" @click="toggleFooter(o)">
                    <div :class="`footer-icon__container footer-icon__${o.id}`" ></div>
                    <span>{{ $t(o.name) }}</span>
                    <div v-if="o.isLive" class="dot-wrapper">
                        <div class="ripple"></div>
                    </div>
                </div>
                <div v-else class="footer-icon__fifa">
                    <div class="relative" @click="toggleFifaFooter(o)">
                        <div class="footer-icon__fifa-fake"></div>
                        <div class="footer-icon__fifa-fake2"></div>
                        <div v-if="isShowSportTypeOption" class="footer-icon__fifa-img selected"></div>
                        <div v-else :class="`footer-icon__fifa-img ${this.currentSportType}`"></div>
                        <!-- <div class="footer-icon__fifa-img" :class="{selected : o.selected}"></div> -->
                        <!-- <img class="footer-icon__fifa-img" src="../../static/images/icons/icon_footer_fifa_unselect.png"> -->
                    </div>
                    <div v-show="isShowSportTypeOption" class="footer-sport-type__wrapper" >
                        <img v-for="(o, index) in sportType" :key="index" @click="handleFooterSportType(o)" class="footer-sport-type__icon" :src="require(`../../static/images/icons/icon_footer_${o.name}.png`)" />
                    </div>
                </div>
            </div>
        </ul>
    </div> 
	<PopUpSignInIndexMobile ref="PopUpSignInIndexMobileRef" :pathName="`/${currentLocale}/fantasysport`" :message="$i18n.t('FANTASY_SPORT_LOGIN_TIPS')" ></PopUpSignInIndexMobile>

</template>

<script>
import {mapGetters,mapMutations,mapActions} from 'vuex'
import MainLogo from '@/components/indicator/MainLogo.vue'
import articles from '@/js/seoArticles/footer.js';
import PopUpSignInIndexMobile from "@/components/user/PopUpSignInIndexMobile.vue"
import FooterMenuIcon from "@/components/ui/FooterMenuIcon.vue";

export default {
    components:{
        MainLogo,
        FooterMenuIcon,
        PopUpSignInIndexMobile
    },
    computed:{
        ...mapGetters([              
            'currentLocale',
            'currentSportType',
            'footerTabs',
            'isMobile',
            'isLogin'
        ]),
    },
    watch: {
        currentLocale: {
        deep: true,
        handler(newVal, oldVal) {
                this.getTiktokLink();
                this.getFooterArticleLinks();
            },
        },
    },
    data() {
        return {
          isRefreshing: false,
          footerLinks: null,
          currentYear: new Date().getFullYear(),
          isShow: true,
          targetRouteName: ["signup","signin","forgetpassword"],
          isShowTiktok: false,
          isShowSportTypeOption: false,
          sportType: [
            {
                name: "football",
                isSelect: false
            },
            {
                name: "basketball",
                isSelect: false
            }
          ]
        }
    },
    mounted() {
      this.getFooterArticleLinks();
      this.setDisplayStatus();
    },
    methods: {
          ...mapActions([
            "refresh",
            "defineSportType"
        ]),
        ...mapMutations([
            "CURRENT_SUB_NAVI_MENU_CHANGE",
        ]),

        setDisplayStatus() {
            if (this.isMobile && this.targetRouteName.includes(this.$route.name)) {
                this.isShow = false;
            }
        },

        handleRefresh(){
            this.isRefreshing = true;
            setTimeout(() => this.isRefreshing  = false, 500);

            //this.$router.go()
            //location.reload();
            this.refresh()
        },

        getTiktokLink() {
            const tiktokLink = {
                'vn' : 'https://www.tiktok.com/@asiasportvn',
                'th' : 'https://www.tiktok.com/@asiasport.thai',
                'kh' : 'https://www.tiktok.com/@asiasport.kh'
            }
            this.isShowTiktok = tiktokLink[this.currentLocale];
            return tiktokLink[this.currentLocale]
        },

        getFooterArticleLinks() {
            if (typeof articles[this.currentLocale] !== "undefined") {
                this.footerLinks = articles[this.currentLocale];
            }
        },
        toggleFifaFooter(o) {
            this.isShowSportTypeOption = ! this.isShowSportTypeOption;
             o.selected = !o.selected;
        },
        handleFooterSportType(o) {
        
         
            const whiteListPage = [
                "news",
                "notification"
            ]
            const whiteListChangePage = [
                "home",
                "highlight",
                "fantasysport"
            ]
             console.log(o);
            let fullPath = window.location.pathname.split("/");

            let routeName =  this.$route.meta.name;
            
            if (whiteListPage.includes(fullPath[2])) {
                this.defineSportType(o.name)
            } 
            else if (whiteListChangePage.includes(routeName)) {
                fullPath[2] = o.name;
                this.$router.replace(fullPath.join("/") + window.location.search);
            } else {
                if (this.currentSportType !== o.name) {
                    this.$router.push(`/${o.name}/matches`)
                }
              
            }
    
            this.isShowSportTypeOption = ! this.isShowSportTypeOption;

        },
        toggleFooter(o) {
      
            if (o.name === "FANTASY") {
                if (this.isLogin) {
                    this.$router.push({path:`/${this.currentLocale}/${this.currentSportType}/${o.pathOnly}`})
                    this.isShowSportTypeOption = false;
                    setTimeout(() => {
                        o.selected = !o.selected;
                    }, 300);
                } else {
                    this.$refs.PopUpSignInIndexMobileRef.handleOpen();
                }
            } else {
                if(o.id === 3) { //news
                    this.$router.push({path:`/${this.currentLocale}/${o.pathOnly}`})
                } else {
                    this.$router.push({path:`/${this.currentLocale}/${this.currentSportType}/${o.pathOnly}`})
                }
                this.isShowSportTypeOption = false;
                // setTimeout(() => {
                //     o.selected = !o.selected;
                // }, 300);
            }             
        }
    },
}
</script>

<style>

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }}
    .footer-refresh{
        -webkit-animation:spin 2s linear infinite;
        -moz-animation:spin 2s linear infinite;
        animation:spin .8s linear infinite;
    }
    .footer-desktop__desc-wrapper {
        background-color: rgb(0 0 0);
        border-top:.1rem solid #35454D;
        border-bottom:.1rem solid #35454D
    }
    .footer-desktop__desc-container{
        width: 48.5%;
    }
    .footer-desktop__desc-title{
        font-size:.7rem;
        margin: 1rem 0;
        color : var(--color-grey-light)

    }
    .footer-desktop__desc-content{
        font-size:.6rem;
        color: var(--color-theme-2);
        margin-bottom: 1rem;
    }   
    .footer-link__wrapper {
        display: flex;
        margin-top: 1rem;
    }
    .footer-link {
        padding-right: 3rem;
    }
    .footer-link a {
        font-size: 0.688rem;
        color: var(--color-grey-80);
    }
    .footer-link a:hover {
        color: var(--color-grey-light);
        text-decoration: underline;
    }
    .footer-desktop__social-wrapper{
        padding: 1rem 0;
        background-color: black;
    }
    .footer-desktop__social-container{
        display: flex;
        margin-right: 3rem;
    }
    .footer-desktop__copy-right{
        margin-right: 3.5rem;
        color:var(--color-theme-2)
    }
    .footer-desktop__social-icon{
        cursor: pointer;
        padding: 0 1rem;
        width: 1.2rem;
        height: 1.2rem;
    }
    .footer-desktop__social-icon img{
        width:100%;
        height: 100%;
        object-fit: contain;
    }
    .footer-desktop__wrapper{
        position: relative;
    }
    .footer-desktop__privacy:hover{
        text-decoration: underline;
    }
    .footer-wrapper{
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
        background-color: var(--bg-theme);
        border-top: .1rem solid #35454D;
        box-shadow: 0 0 7px rgba(0,0,0,.3);
    }
    .footer-container{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin: 0.5rem 0 .8rem 0;
    }
    .footer-icon__wrapper{
        -webkit-flex: 1;
        flex: 1;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        color: gray;
        font-size: .7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .footer-icon__container{
        display: block;
        margin: 0 auto ;
        width: 1.5rem;
        height: 1.5rem;
        transition: all.5s;
        margin-bottom: .2rem;

    }
    .footer-icon__wrapper.selected{
        color:var(--color-theme-light); 
    }
        
    .footer-icon__1{
        background: url(../../static/images/icons/icon_footer_home_unselect.png) center/contain no-repeat;
    }
    .selected .footer-icon__1 {
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../static/images/icons/icon_footer_home_selected.png) center/contain no-repeat;
    }
    .footer-icon__2{
        background: url(../../static/images/icons/icon_footer_highlights_unselect.png) center/contain no-repeat;
    }
    .selected .footer-icon__2{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../static/images/icons/icon_footer_highlights_selected.png) center/contain no-repeat;
    }
    .footer-icon__3{
        background: url(../../static/images/icons/icon_footer_news_unselect.png) center/contain no-repeat;
      
    }
    .selected  .footer-icon__3{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../static/images/icons/icon_footer_news_selected.png) center/contain no-repeat;
       
    }
     .footer-icon__4{
        background: url(../../static/images/icons/icon_footer_event_unselect.png) center/contain no-repeat;
      
    }
    .selected  .footer-icon__4{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../static/images/icons/icon_footer_event_selected.png) center/contain no-repeat;
     
    }
    .footer-icon__8{
        background: url(../../static/images/icons/icon_footer_fantacy_1_unselect.png) center/contain no-repeat;
      
    }
    .selected  .footer-icon__8{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../static/images/icons/icon_footer_fantacy_1_selected.png) center/contain no-repeat;
     
    }
 
    .selected  .footer-icon__fifa-img{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../static/images/icons/icon_footer_close.png) center/contain no-repeat;
    }
    .footer-icon__fifa-img{
        background: url(../../static/images/icons/icon_footer_sport_football_selected.png) center/contain no-repeat;
    }
    .footer-icon__fifa-img.football{
        background: url(../../static/images/icons/icon_footer_sport_football_selected.png) center/contain no-repeat;
    }
    .footer-icon__fifa-img.basketball{
        background: url(../../static/images/icons/icon_footer_sport_basketball_selected.png) center/contain no-repeat;
    }
    .footer-icon__hkfs-img{
        background: url(../../static/images/icons/icon_footer_fantasy_unselect.png) center/contain no-repeat;
    }
    .selected  .footer-icon__hkfs-img{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../static/images/icons/icon_footer_hkfs_selected.png) center/contain no-repeat;
    }
    /* .footer-icon__4{
        background: url(../../static/images/icons/icon_footer_notification_unselect.png) center/cover no-repeat;
        background-size: inherit;
    }
    .router-link-active  .footer-icon__4{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../static/images/icons/icon_footer_notification_unselect.png) center/cover no-repeat;
        background-size: inherit;
    } */
    
    .footer-icon__5{
        background: url(../../static/images/icons/icon_footer_refresh.png) center/contain no-repeat;
        background-size: inherit;
    }
    .router-link-active  .footer-icon__5{
        animation: nimateBall .5s ease forwards;
        -webkit-animation: nimateBall .5s ease forwards;
        background: url(../../static/images/icons/icon_footer_more_unselect.png) center/contain no-repeat;
        background-size: inherit;
    }
    .footer-icon__wrapper .dot-wrapper {
        top: -0.3rem;
        right: 0.3rem;
    }
    .footer-icon__fifa{
        position: absolute;
        top: -14px;
        right: 81%;
    }
    .footer-icon__fifa-fake{
        width: 58px;
        height: 58px;
        position: absolute;
        border-radius: 50%;
        z-index: 10;
        left: -5px;
        top: -2px;
        background-color: #000000;
        border: .1rem solid #35454D;
    }
    .footer-icon__fifa-fake2{
        width: 64px;
        height: 69px;
        position: absolute;
        background-color: #020611;
        z-index: 123;
        top: 6.8px;
        left: -5px;
    }
    .footer-icon__fifa-img{
        z-index: 123;
        position: absolute;
        left: -3px;
        width: 57px;
        display: block;
        height: 57px;
    }

    .footer-sport-type__wrapper{
        position: absolute;
        top: -4rem;
        left: -41px;
        border-radius: 1rem;
        display: flex;
    }
    .footer-sport-type__icon{
        width: 55px;
        height: 55px;
        margin: 0 0.5rem
    }
    @media (max-width: 768px){
        /* .footer-desktop__wrapper {
            display: none;
        } */
        .footer-desktop__social-wrapper {
            display: none;
        }
        .footer-desktop__desc-container{
            width: auto;
            padding: 0 0.8rem 1.5rem 0.8rem;
        }
        .footer-desktop__desc-wrapper{
            border-bottom: none;
        }
        .footer-link__wrapper {
            flex-wrap: wrap;
            margin: 1rem 0;
        }
        .footer-link {
            padding-right: 1.5rem;
        }
        .footer-mobile__desc-wrapper{
            margin: 0 .8rem;
            border-top: .1rem solid #35454D;
        }
        .footer-mobile__desc-container{
            width: auto;
        }
        .footer-wrapper{
             display: block;
        }
        .footer-mobile__privacy{
            font-size: 0.6875rem;

            margin-bottom: .5rem;
        }
        .footer-mobile__privacy a {
            color:var(--bg-selected);
        }
        .footer-mobile__privacy-wrapper{
            padding: 1rem 0;
        }
        .footer-mobile__copyright{
            color: #5C7784;
            font-size: 0.6875rem;
        }
    }
</style>