<template>
  <div class="app-home__wrapper" v-if="isMobile">
    <div class="app-home__content">
      <div class="app-home__close" @click="handleClose()"><img src="../../../../static/images/icons/icon_close_grey.png"></div>
      <div class="app-home__logo"><MainLogoIcon class="app-home__logo-img"></MainLogoIcon></div>
      <div>
        <h2 class="app-home__title">{{projectName}}</h2>
        <p class="app-home__desc">{{$t('APP_DESC')}}</p>
        <div>
          <span v-for="index in 5" :key="index"><img src="../../../../static/images/icons/icon_star.png"></span>
        </div>
      </div>
      </div>
      <div class="app-home__open-wrapper">
        <a :href="APPDownloadLink" target="_blank" class="app-home__open">{{$t('OPEN')}}</a>
      </div>
  </div>
</template>

<script>
import MainLogoIcon from '@/components/indicator/MainLogoIcon.vue'
import config from '@/js/config.js'

import {mapGetters,mapActions} from 'vuex'

export default {
  components: {
    MainLogoIcon
  },
  data() {
    return {
      APPDownloadLink: '',
      projectName: config.projectName,
      os:''
    }
  },
  computed: {
        ...mapGetters([
            'isShowAppDownloadHome',
            'isMobile',
            'currentCountryObj',
            'currentLanguageObj',
        ]),
    },
    mounted() {

      this.init();
    },
    watch: {
      currentCountryObj: {
        deep: true,
        handler(newVal, oldVal) {
          this.init();
        }
      },
      currentLanguageObj: {
        deep: true,
        handler(newVal, oldVal) {
          this.init();
        }
      },
    },
    methods: {
      ...mapActions([
          "isShowAppDownloadHomePageEvent",
      ]),
      init() {
        let currentCountryObj = this.currentCountryObj
        let displayLocale = this.currentLanguageObj.displayLocale;
        let mobileOS = this.$tools.getMobileOperatingSystem();
        this.os = mobileOS;

        if (mobileOS.indexOf('iOS') !== -1) {
          this.APPDownloadLink = config.app.apple.link
        } else {
          if (currentCountryObj !== null && currentCountryObj.countryCode === 'TH') {
            if (displayLocale === 'th') {
              this.APPDownloadLink = config.apk.thLink;
            } else if (displayLocale === 'en') {
              this.APPDownloadLink = config.apk.thEnLink;
            }
          } else if (currentCountryObj === null || currentCountryObj.countryCode !== 'TH') {
            this.APPDownloadLink = config.app.android.link
          }
        }
      },

      handleClose() {
        this.isShowAppDownloadHomePageEvent(false);
      }
    },
}
</script>

<style>


.app-home__wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: black;
  padding: 0.8125rem 0;
  position: fixed;
  z-index: 4;
  width: 100%;
}
.app-home__content{
  display: flex;
  align-items: center;
}
.app-home__close{
  margin: 0 1rem
}
.app-home__logo{
  background-color: var(--color-theme);
  padding: 0.62rem;
  border-radius: 1rem;
  margin-right: .5rem;
}
.app-home__title{
  font-size: 1.125rem;
}
.app-home__desc{
  font-size: 0.5625rem;
}
.app-home__logo-img{
  width: 2.9375rem;
  height: auto;
}
.app-home__open-wrapper{
  margin-right: .8rem;
}
.app-home__open{
  background-color: var(--bg-selected);
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  color: black;
  font-weight: bold;
}
@media (min-width: 320px) {
.app-home__logo-img {
    width: 2rem; 
  }
}
</style>