import en from "./en";
import vn from "./vn";
import th from "./th";
import kh from "./kh";
import cn from "./cn";
import zhHans from "./zh-Hans";
import zhHant from "./zh-Hant";
import pt from "./pt";

import config from "@/js/config.js";
const translations = { en, vn, th, kh, cn, zhHans, zhHant, pt };

const projectName = config.projectName;

const defaultMetadata = (route) => (
{
    title: `Live Football Results, Live Scores, Fixtures, Video Highlights - ${projectName}`,
    description: `AsiaSport is a current hub where all the sports fans gather for newsfeed. Every aspect of the tournament will be made accessible at all times. When a football match is held, the live scores will be updated in real-time as it progresses.`
});

export const EMPTY_METADATA = defaultMetadata;

export function getMetadata(routeName, translationKey, route) {
    if (translationKey === "zh-Hans") {
        translationKey = "zhHans";
    } else if (translationKey === "zh-Hant") {
        translationKey = "zhHant";
    }
    let metadataMap = translations[translationKey];

    if (!metadataMap) {
        return defaultMetadata;
    }

    if (!metadataMap[routeName]) {
        if (metadataMap[route.meta.parent]) {
            return metadataMap[route.meta.parent]
        } else {
            return metadataMap['home'];
        }    
    }

    return metadataMap[routeName];
}