import config from "@/js/config.js";
import footerLinks from "@/js/seoArticles/footer.js";

const projectName = config.projectName;
const description = `AsiaSport is a current hub where all the sports fans gather for newsfeed. Every aspect of the tournament will be made accessible at all times. When a football match is held, the live scores will be updated in real-time as it progresses.`
const footerLinksPageMeta = {};
Object.values(footerLinks).forEach(arr => {
    if (arr.length > 0) {
        arr.forEach(article => {
            footerLinksPageMeta[article.id] = (route) => ({
                title: article.titleTag,
                description: article.metaDescription,
            });
        });
    }
});

export default {
    'home': (route) => (
    {
        title: `วิเคราะห์บอลวันนี้ ผลบอลสด โปรแกรมแข่งขัน ข่าวกีฬา ไฮไลท์ - ${projectName}`,
        description: description
    }),
    'highlights': (route) => ({
        title: `ดูบอลออนไลน์ ไฮไลท์ฟุตบอล บอลสด ผลบอลเมื่อคืน - ${projectName}`,
        description: description
    }),
    'news': (route) => ({
        title: `ข่าวกีฬา ข่าวแมนยู ข่าวลิเวอร์พูล ข่าวบอล - ${projectName}`,
        description: description
    }),
    'event': (route) => ({
        title: `โปรแกรมบอล โปรแกรมฟุตบอล การแข่งขันบอล- ${projectName}`,
        description: description
    }),
    'account': (route) => ({
        title: `My Profile - ${projectName}`,
        description: description
    }),
    'player': (route) => ({
        title: `${route.params.playerName
            .split("-")
            .join(" ")} โปรไฟล์ - ${projectName}`,
        description: description
    }),
    'matchDetail': (route) => ({
       title: `${route.params.opponent
            .split("-")
            .join(" ")} คลิปไฮไลท์ - ${projectName}`,
        description: description
    }),
    'highlightsDetail': (route) => ({
        title: `${route.params.opponent
             .split("-")
             .join(" ")} คลิปไฮไลท์ - ${projectName}`,
         description: description
     }),
    ...footerLinksPageMeta,
};