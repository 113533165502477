import { createSSRApp, createApp, h } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
const MobileDetect = require("mobile-detect");
//custom js
import createStore from "./store";
import tools from "@/js/tools";
import config from "@/js/config";

import $ from "jquery";

//library
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { VueReCaptcha } from 'vue-recaptcha-v3'

import VueAnalytics from "vue-analytics";
import VueMeta from "vue-meta";
import { DatePicker } from "@/lib/v-calendar";

import { createHead } from "@vueuse/head";

//translations
import en from "./translations/en.json";
import vn from "./translations/vn.json";
import th from "./translations/th.json";
import kh from "./translations/kh.json";
import cn from "./translations/cn.json";
import zhHans from "./translations/zh-Hans.json";
import zhHant from "./translations/zh-Hant.json";
import pt from "./translations/pt.json";
import { createI18n } from "vue-i18n";

//components
import Loading from "@/components/indicator/Loading.vue";
import NoData from "@/components/indicator/NoData.vue";
import Icon from "@/components/indicator/Icon.vue";
import Navigation from "@/components/indicator/Navigation.vue";
// import Pagination from 'v-pagination-3';
import MetaHeader from "@/components/metadata/MetaHeader.vue";

import '../static/css/video.css';
import '../static/css/components.css';
import '../static/css/components-responsive.css';
import '../static/css/common.css';
import '../static/css/common-responsive.css';


export default function() {
  const store = createStore();

  const isSSR = typeof window === "undefined";

  const rootComponent = {
    render: () => h(App),
    components: { App },
  };

  const app = (isSSR ? createSSRApp : createApp)(rootComponent);

  const i18n = createI18n({
    locale: "en",
    messages: {
      en: en,
      vn: vn,
      th: th,
      kh: kh,
      cn: cn,
      'zh-Hans': zhHans,
      'zh-Hant': zhHant,
      pt: pt,
    },
    fallbackLocale: "en",
  });
  
  app.component("Loading", Loading);
  app.component("NoData", NoData);
  app.component("Icon", Icon);
  app.component("Navigation", Navigation);
  // app.component('pagination', Pagination);
  app.component("MetaHeader", MetaHeader);

  app.config.globalProperties.$tools = tools;
  app.config.globalProperties.$config = config;

  if (!isSSR) {
    //ssr run mixin after the main page created, temporary solution for setting currentLocale's value, coz most of the API need currentLocal param
    let fullPath = window.location.pathname.split('/');        
    let locale = fullPath[1] ;
    if (locale === '') {
      locale = config.defaultLocale;
    }

    store.commit('CURRENT_LOCALE_CHANGE_EVENT', locale);

    //detect is mobile or not using user agent
    const md = new MobileDetect(
      window.navigator.userAgent
    );
    store.commit('DEVICE_CHANGE_SIZE_EVENT', !!md.mobile())

    //store user data to vuex
    let localStorageUser = localStorage.getItem("user");
    //if local storage has user data mean status is Login
    if (localStorageUser !== null) {
        if (localStorageUser !== 'null') {
         // store.commit('IS_LOGIN')
        }
      }
    //
  } 

  const head = createHead();

  app
    .use(store)
    .use(router)
    .use(PerfectScrollbar)
    .use(i18n)
    .use(head)
    .use(VueReCaptcha, { 
      siteKey: process.env.VUE_APP_RECAPTCHA_V3,  
      loaderOptions: {
        autoHideBadge: true
      } 
    })
  return {
    app,
    head,
    router,
    store,
  };
}
