import { createStore } from "vuex";
import api from "@/js/api";
import config from "@/js/config.js";
import router from "@/router";
import moment from "moment";

const USER = config.localStorageUser;
const USER_LANG = config.localStorageUserLang;
const USER_COUNTRY_CODE = config.localStorageUserCountryCode;

const storeOptions = {
  state: {},
  mutations: {
    METADATA_PROVIDER: (state, data) => {
      // if (typeof data !== "function") {
      //   throw "Metadata provider must be a function.";
      // }

      state.metadataProvider = data;
    },
    // matchListing
    MATCHODDSMORE: (state, data) => {
      if (data) {
        state.isOddsMore = data;
      } else state.isOddsMore = !state.isOddsMore;
    },
    MATCHODDSMENU: (state, data) => {
      if (data) {
        state.isOddsMenu = data;
      } else state.isOddsMenu = !state.isOddsMenu;
    },
    MATCHODDS_OPTION_HANDLER: (state, data) => {
      if (data && data.attr) {
        state[`${data.side}Odds`][data.table][data.attr] = data.data;
      } else state[`${data.side}Odds`][data.table] = data.data;
    },
    MATCHODDS_ISSAME_HANDLER: (state, data) => {
      state[`${data}Odds`]["isSameLeague"] = !state[`${data}Odds`][
        "isSameLeague"
      ];
    },
    MATCHODDS_LEAGUE_HANDLER: (state, data) => {
      if (!state[`${data.side}Odds`]["leagueList"].includes(data.data)) {
        state[`${data.side}Odds`]["leagueList"].push(data.data);
      } else
        state[`${data.side}Odds`]["leagueList"] = state[`${data.side}Odds`][
          "leagueList"
        ].filter((l) => l !== data.data);
    },
    // matchListing End

    REFRESH: (state, data) => {
      state.refresh = !state.refresh;
    },
    MANUAL_REFRESH_MATCH_DETAIL_SCORE: (state, data) => {
      state.manualRefreshMatchDetailScore = !state.manualRefreshMatchDetailScore;
    },
    MANUAL_REFRESH_MATCH_DETAIL: (state, data) => {
      state.manualRefreshMatchDetail = !state.manualRefreshMatchDetail;
    },
    
    IS_REDIRECT: (state, data) => {
      state.isRedirect = data;
    },
    IS_HAS_LIVE: (state, data) => {
      state.hasLive = data;
    },
    COMPONENT_KEY: (state, data) => {
      state.COMPONENT_KEY = !state.refresh;
    },
    // generate, set language selection list based on counstry code
    COUNTRY_CODE_TO_LANG_OBJ_LIST: (state, data) => {
      let defaultCountryObj = {};
      let langObjList = [];

      config.countryList.forEach(country => {
        if (country.countryCode === data.countryCode) {
          defaultCountryObj = country;
        }
      });
      
      let supportedLocaleList = (typeof defaultCountryObj.supportedLocale === "undefined") ? ['en'] : defaultCountryObj.supportedLocale;
      
      supportedLocaleList.forEach((locale, i) => {
        config.languageList.forEach(obj => {
          if (obj.displayLocale === locale) {
            if (state.userInfo.preferredLanguage && data.action === "No_Action") { // if logged in & not switching Country
              obj.selected = (obj.locale === state.userInfo.preferredLanguage) ? true : false;
            } else { // if not logged in / logged in but switching country
              obj.selected = (obj.displayLocale === state.currentLocale) ? true : false;//(i == 0) ? true : false;
            }
            

            if (obj.displayLocale === 'en' && typeof defaultCountryObj.supportedLocale !== "undefined") {
              
              obj.apiParam = 'en_' + defaultCountryObj.countryCode;
            }
            langObjList.push(obj);
          }
        });

        // state.currentLanguageObj = null;
        // state.currentLanguageObj = config.languageList;
      });
      
      state.userLangConfig = langObjList;
      localStorage.setItem(USER_LANG, JSON.stringify(state.userLangConfig));
    },
    UPDATE_USER_LANG_CONFIG: (state, data) => {
      let countryBasedLangObjList = state.userLangConfig;
      if (countryBasedLangObjList !== null) {
          countryBasedLangObjList.forEach(obj => {
            obj.selected = (obj.displayLocale === data) ? true : false;
          });
          state.userLangConfig = countryBasedLangObjList;
        
        localStorage.setItem(USER_LANG, JSON.stringify(state.userLangConfig));
      }
    },
    
    UPDATE_USER_SELECTED_LANG_CONFIG_OBJ: (state, data) => {
      if (state.userLangConfig !== null) {
        state.userSelectedLangConfigObj = state.userLangConfig.filter(lang => lang.selected === true)[0];
      }
    },
    REMOVE_USER_LANG_CONFIG: (state, data) => {
      localStorage.removeItem(USER_LANG);
      state.userLangConfig = null;
    },
    CURRENT_COUNTRY_LOCALE_CHANGE_EVENT: (state, data) => {
      let currentCountryObj;
      config.countryList.forEach(o => {
        if (o.countryCode === data.countryCode) {
          currentCountryObj = o;
        }
      });
      
      state.currentCountryList = [];
      state.currentCountryList = config.countryList;
      state.currentCountryObj = currentCountryObj;
      state.userCountryCode = data.countryCode;

      config.languageList.forEach((x) => {
        if (x.locale === data.locale) {
          x.selected = true;
          state.currentLanguageObj = x;
          state.currentLocale = x.displayLocale;
        } else {
          x.selected = false;
        }
      });

      state.currentLanguageObj.apiParam = `${data.locale}_${data.countryCode}`;
      
      if (typeof window !== "undefined") {
        let fullPath = window.location.pathname.split("/");
        fullPath[1] = state.currentLocale;
        router.replace(fullPath.join("/") + window.location.search);
      }

    },
    CURRENT_COUNTRY_CHANGE_EVENT: (state, data) => {
      let currentCountryObj = null;
      config.countryList.forEach(o => {
        if (o.countryCode === data) {
          currentCountryObj = o;
        }
      });
      
      state.currentCountryList = [];
      state.currentCountryList = config.countryList;
      state.currentCountryObj = currentCountryObj;
      state.userCountryCode = data;

      const selectedCountry = state.currentCountryList.filter(x=>x.countryCode === data)[0];
      if (selectedCountry) {
        state.currentLanguageObj.apiParam = `${state.currentLanguageObj.locale}_${selectedCountry.countryCode}`
      }
   
    },
    CURRENT_LOCALE_CHANGE_EVENT: (state, data) => {
      let displayLocale;
      let currentLanguageObj;

      if (data === "vi") {
        data = "vn";
      } else if (data === "km") {
        data = "kh";
      }

      if (state.userLangConfig !== null) {
        state.userLangConfig.forEach((x) => { //config.languageList
          if (x.displayLocale === data) {
            x.selected = true;
            currentLanguageObj = x;
            displayLocale = x.displayLocale;
          } else {
            x.selected = false;
          }
        });

        // user altered "/:locale" of the path to unsupported language
        if (typeof currentLanguageObj === "undefined") {
          currentLanguageObj = state.userLangConfig.filter(obj => obj.selected === true)[0];
        }
      } else {
        config.languageList.forEach((x) => {
          if (x.displayLocale === data) {
            x.selected = true;
            currentLanguageObj = x;
            displayLocale = x.displayLocale;
          } else {
            x.selected = false;
          }
        });
      }

      if (typeof window !== "undefined") {
        let fullPath = window.location.pathname.split("/");
        fullPath[1] = displayLocale;
        router.replace(fullPath.join("/") + window.location.search);
      }

      state.currentLanguageObj = currentLanguageObj;
      // debugger
      state.currentLanguageList = config.languageList;
      state.currentLocale = displayLocale;
    },
    SET_USER_COUNTRY_CODE: (state, data) => {
      state.userCountryCode = data;
      // localStorage.setItem(USER_COUNTRY_CODE, state.userCountryCode);
    },
    REMOVE_USER_COUNTRY_CODE: (state, data) => {
      state.userCountryCode = null;
      // localStorage.removeItem(USER_COUNTRY_CODE);
    },
    SET_CURRENT_PATH_NAME: (state, data) => {
      state.currentPathName = data;
    },
    USER_SWITCH_COUNTRY_LANG: (state, data) => {
      state.userSwitchCountryLang++;
    },
    HOME_PAGE_LEAGUE_PARAMS_CHANGE_EVENT: (state, data) => {
      state.homePageLeagueParams = data;
    },
    DEVICE_CHANGE_SIZE_EVENT: (state, data) => {
      state.isMobile = data;
    },
    POPUP_SIGN_IN_MODULE_EVENT: (state, data) => {
      state.popUpSignInModule = data;
    },
    TOASTR_CHANGE_EVENT: (state, data) => {
      state.toastr.isOpen = data.isOpen;
      state.toastr.type = data.type;
      state.toastr.msg = data.msg;
      state.toastr.duration = data.duration;
    },
    TOASTR_ICON_WITH_BG_CHANGE_EVENT: (state, data) => {
      state.toastrIconWithBg.isOpen = data.isOpen;
      state.toastrIconWithBg.type = data.type;
      state.toastrIconWithBg.msg = data.msg;
      state.toastrIconWithBg.duration = data.duration;
    },
    TOASTR_FAV_CHANGE_EVENT: (state, data) => {
      state.toastrFav.isOpen = data.isOpen;
      state.toastrFav.type = data.type;
      state.toastrFav.msg = data.msg;
      state.toastrFav.duration = data.duration;
      state.toastrFav.isAddedFav = data.isAddedFav;
    },
    FAV_LOGIN_TIPS_CHANGE_EVENT: (state, data) => {
      state.favLoginTips.isOpen = data.isOpen;
      state.favLoginTips.triggerOnParent = data.triggerOnParent;
      state.favLoginTips.event = data.event;
      state.favLoginTips.type = data.type;
      state.favLoginTips.callBackUrl = data.callBackUrl;
      state.favLoginTips.message = data.message ? data.message : 'FAV_LOGIN_TIPS';
    },
    FAV_MODAL_CHANGE_EVENT: (state) => {
      state.isFavModelChange = !state.isFavModelChange;
    },

    HAS_NEW_NOTIFICATION_CHANGE_EVENT: (state, data) => {
      if (data) {
        state.newNotificationData = data;
        state.hasNewNotification = true;
      } else {
        state.hasNewNotification = false;
      }
    },
    HAS_COUNTRY_AND_LANGAUGE_CHANGE_EVENT: (state, data) => {
      state.hasCountryAndLangaugeChange =  !state.hasCountryAndLangaugeChange;
    },
    IS_SHOW_FAV_MODAL: (state, data) => {
      if (data) {
        state.isShowFavModal = !data.isClose;
      } else {
        state.isShowFavModal = !state.isShowFavModal;
      }
      
    },
    IS_SHOW_BASKETBALL_FAV_MODAL: (state, data) => {
      if (data) {
        state.isShowBasketballFavModal = !data.isClose;
      } else {
        state.isShowBasketballFavModal = !state.isShowBasketballFavModal;
      }
      
    },
    IS_SHOW_HEADER: (state, data) => {
      state.isShowHeader = data;
    },
    IS_SHOW_APP_DOWNLOAD_HOMEPAGE: (state, data) => {
      state.isShowAppDownloadHome = data;
    },
    IS_LOGIN: (state, data) => {
      let userInfo = JSON.parse(localStorage.getItem(USER));
      if (userInfo !== null) {
        state.isLogin = true;

        const selectedCountry = state.currentCountryList.filter(x=>x.countryCode === userInfo.preferredCountry)[0];
        state.currentLanguageObj.apiParam = `${state.currentLanguageObj.locale}_${selectedCountry.countryCode}`;
        state.userInfo = userInfo;
      } else {
        state.isLogin = false;
      }
  
    },
    LOGOUT: (state, data) => {
      state.isLogin = false;

      localStorage.removeItem(USER);

      state.userInfo = {};
    },
    SET_USER_DATA: (state, data) => {
      
      if (data === null) {
        state.isLogin = false;
        localStorage.removeItem(USER);

        state.userInfo = {};

        window.location.href = `${window.location.protocol}//${
          window.location.host
        }/${location.pathname.split("/")[1]}`;
      } else {
        localStorage.setItem(USER, JSON.stringify(data));
        state.userInfo = data;

        state.isLogin = true;
      }
    },

    SUCUESS_GET_MATCH_LIST(state, data) {
      state.matchList = data.result.leagueMatchList;
    },
    MATCH_ID: (state, data) => {
      state.matchId = data;
    },

    SUCUESS_GET_MATCH_LIST(state, data) {
      state.matchList = data.result.leagueMatchList;
    },

    SUCUESS_GET_MATCH_DETAIL_SCORE(state, data) {
      if (data.awayTeamScore == "") {
        data.awayTeamScore = "-";
      }
      if (data.homeTeamScore == "") {
        data.homeTeamScore = "-";
      }
      let blackList = config.matchEndedStatus;

      if (blackList.indexOf(data.matchStatus) != -1) {
        state.isStopTimmer = true;
      } else {
        state.isStopTimmer = false;
      }
      state.matchDetailScore = data;
    },
    SUCUESS_GET_MATCH_DETAIL_INFO(state, data) {
      state.matchDetailInfo = data;
    },
    SUCUESS_GET_TEAM_INFO(state, data) {
      let team = data.teams.team;
      state.teamInfo = team;

      let squad = team.squad.player; // filter naming for position
      let position;

      // for (let i = 0; i<squad.length; i++) {
      // 	switch (squad[i].pos) {
      // 		case 'A':
      // 			position = "ATTACKERS";
      // 		break;
      // 		case 'D':
      // 			position = "DEFENDERS";
      // 		break;
      // 		case 'G':
      // 			position = "GOAL_KEEPERS";
      // 		break;
      // 		case 'M':
      // 			position = "MIDFIELDERS";
      // 		break;
      // 	}
      // 	squad[i].position = position;
      // }

      if (team.trophies === null) {
        state.teamInfo.honours = null;
      } else {
        state.teamInfo.honours = team.trophies.trophy;
      }

      state.teamInfo.squadData = squad;

      state.teamInfo.sidelinedData =
        team.sidelined === null ? null : team.sidelined.player;

      state.teamInfo.transferInData = team.transfers.in.player;
      state.teamInfo.transferOutData = team.transfers.out.player;
    },

    SUCUESS_GET_PLAYER_INFO(state, data) {
      let playerInfo = data.players.player;
      state.playerInfo = playerInfo;

      if (data === null) {
        state.playerInfo.isShowData = false;
        return;
      } else {
        state.playerInfo.isShowData = true;
      }

      if (playerInfo.transfers === null) {
        state.playerInfo.transferData = null;
      } else {
        state.playerInfo.transferData = playerInfo.transfers.transfer;
      }

      if (playerInfo.trophies === null) {
        state.playerInfo.honours = null;
      } else {
        state.playerInfo.honours = playerInfo.trophies.trophy;
      }

      if (playerInfo.statistic === null) {
        state.playerInfo.statisticData = null;
      } else {
        state.playerInfo.statisticData = playerInfo.statistic.club;
      }
    },

    COMMENT_LIST_EVENT(state, data) {
      state.commentListInfo.totalComments = data.totalComments;
      // init value
      data.comments.forEach((x) => {
        x.isShowReply = false;
        x.isShowChildComment = false;
        x.isShowChildCommentReply = false;
      });

      if (data.comments.isMore) {
        data.comments.forEach((x) => {
          state.commentList.push(x);
        });
      } else {
        state.commentList = data.comments;
        state.firstCommentList = state.commentList[0];
      }
    },

    COMMENT_LIST_CHILD_EVENT(state, data) {
      state.commentList.forEach((x) => {
        if (x.newsCommentId === data.newsCommentParentId) {
          if (data.isShowPreviousComment) {
            for (let i = 0; i < data.comments.length; i++) {
              x.child.unshift(data.comments[data.comments.length - 1 - i]);
            }
            // data.comments.forEach(element => {
            // 	x.child.unshift(element)
            // });
            x.childHasNext = false;
          } else {
            x.child = {};

            x.child = data.comments;

            if (data.hasNext) {
              x.childHasNext = data.hasNext;
            }
          }
        }
      });
    },

    COMMENT_LIST_PUSH_EVENT(state, data) {
      data.comments.forEach((x) => {
        x.isShowReply = false;
        x.isShowChildComment = false;
        x.isShowChildCommentReply = false;
      });

      let latestComment = data.comments[0];

      if (typeof data.parentData !== "undefined") {
        //add new comment list to child
        for (let i = 0; i < state.commentList.length; i++) {
          if (state.commentList[i].newsCommentId === data.newsCommentParentId) {
            state.commentList[i].childCommentListingRes.remainingCommentCount++;

            if (typeof state.commentList[i].child == "undefined") {
              state.commentList[i].child = [];
            }
            state.commentList[i].child.push(latestComment);
          }
        }
      } else {
        state.commentListInfo.totalComments = data.totalComments;
        //add new comment list to parent
        state.commentList.unshift(latestComment);
        state.firstCommentList = state.commentList[0];
      }
    },

    COMMENT_LIST_INFO(state, data) {
      state.commentListInfo.totalComments = data.totalComments;
    },

    NAVIGATION_MENU_EVENT(state, data) {
      let returnResult = data;
      let currentDateTime = new Date();
      let isEventParticipated;
      let finalEventParticipated = true;
      let sportEventMenuId = null;

      for (const x of returnResult) {
        //event haven't end
        if (x.endDate > currentDateTime.getTime()) {
          if (x.participated) {
            isEventParticipated = true;
          } else {
            isEventParticipated = false;
            finalEventParticipated = false;
          }
        } else {
          isEventParticipated = true;
        }
      }
      // let headerTabs = state.headerTabs.filter((x) => x.id === 4)[0];
      let footerTabs = state.footerTabs.filter((x) => x.id === 4)[0];
      
      if (state.currentSportType === "football")
        sportEventMenuId = 4;
      else if (state.currentSportType === "basketball")
        sportEventMenuId = 13;

      state.headerTabs.forEach(mainMenu => {
        let target = mainMenu.children.filter(subMenu => subMenu.id === sportEventMenuId)
        if (target.length > 0)
          target[0].isLive = !finalEventParticipated;
      });
      
      // headerTabs.isLive = !finalEventParticipated;
      // footerTabs.isLive = !finalEventParticipated;
    },
    DEFINED_CURRENT_SPORTTYPE(state, data) {
      state.currentSportType = data;
      state.currentSportTypeParam = state.convertSportType[data];
    },
    CURRENT_SUB_NAVI_MENU_CHANGE(state, data) {
      if (typeof data.sportType !== "undefined") {
        state.currentSportType = data.sportType;
        state.currentSportTypeParam = state.convertSportType[data.sportType];
      }
      if (!state.isMobile) {
        for (let i = 0, n = state.headerTabs.length; i < n; i++) {
          if (state.headerTabs[i].name === data.name) {
            state.headerTabs[i].selected = true;
            state.currentSubNavimenu = state.headerTabs[i].children;
          } else {
            state.headerTabs[i].selected = false;
          }
        }
      } else {
        for (let i = 0, n = state.footerTabs.length; i < n; i++) {
          if (state.footerTabs[i].name === data.name) {
           // state.footerTabs[i].selected = true;
            state.currentMobileNaviMenu = state.footerTabs[i].children;
          } else {
            //state.footerTabs[i].selected = false;
          }
        }
      }
    },
    SET_SELECTED_SUB_NAVI_MENU(state, data) {
      for (let i = 0; i < state.currentSubNavimenu.length; i++) {
        if (state.currentSubNavimenu[i].pathName === data) {
          state.currentSubNavimenu[i].selected = true;
        } else {
          state.currentSubNavimenu[i].selected = false;
        }
      }
    },
    CLEAR_SELECTED_SUB_NAVI_MENU(state, data) {
      for (let i = 0; i < state.currentSubNavimenu.length; i++) {
        state.currentSubNavimenu[i].selected = false;
      }
    },
    COMMON_VERIFY_LOGIN(state, data) {
      let localStorageUser = localStorage.getItem("user");

      //if local storage has user data mean status is Login
      if (localStorageUser !== null) {
        if (localStorageUser !== "null") {
          
          this._actions.isLoginEvent[0]();
        }

        let userInfo = JSON.parse(localStorageUser);
        let countryCode = userInfo.preferredCountry;
        state.userCountryCode = countryCode;
        
        this._actions.currentCountryChangeEvent[0](countryCode);
      }
    },

    KNOCKOUT_LIST_EVENT(state, data) {
      state.matchKnockOutsList = data;
    },

    CONTEST_IS_JOIN_EVENT(state, data) {
      state.isJoinedEvent = data;
    },

    CONTEST_IS_END_EVENT(state, data) {
      state.isEventEnded = data;
    },

    KNOCKOUT_LIVE_EVENT(state, data) {
      if (typeof data.isShow !== "undefined") {
        state.knockOutLive.isShow = data.isShow;
      }

      if (typeof data.isShowIcon !== "undefined") {
        state.knockOutLive.isShowIcon = data.isShowIcon;
      }
    },

    STICKY_BANNER_EVENT(state, data) {
      state.isShowStickyBanner = data;
    },
  },

  getters: {
    metadataProvider: (state) => state.metadataProvider,

    // matchListing
    isOddsMenu: (state) => state.isOddsMenu,
    isOddsMore: (state) => state.isOddsMore,
    homeOdds: (state) => state.homeOdds,
    awayOdds: (state) => state.awayOdds,
    h2hOdds: (state) => state.h2hOdds,
    companyGroup: (state) => state.companyGroup,
    // matchListing End

    refresh: (state) => state.refresh,
    manualRefreshMatchDetailScore: (state) => state.manualRefreshMatchDetailScore,
    manualRefreshMatchDetail: (state) => state.manualRefreshMatchDetail,
    isRedirect: (state) => state.isRedirect,
    hasLive: (state) => state.hasLive,
    componentKey: (state) => state.componentKey,

    matchList2: (state) => state.matchList,
    homePageLeagueParams: (state) => state.homePageLeagueParams,
    matchDetailScore: (state) => state.matchDetailScore,
    matchDetailInfo: (state) => state.matchDetailInfo,
    isStopTimmer: (state) => state.isStopTimmer,
    matchId: (state) => state.matchId,

    currentSportType: (state) => state.currentSportType,
    currentSportTypeParam: (state) => state.currentSportTypeParam,
    currentLocale: (state) => state.currentLocale,
    currentPathName: (state) => state.currentPathName,
    currentCountryList: (state) => state.currentCountryList,
    currentCountryObj: (state) => state.currentCountryObj,
    currentLanguageObj: (state) => state.currentLanguageObj,
    currentLanguageList: (state) => state.currentLanguageList,
    userSwitchCountryLang: (state) => state.userSwitchCountryLang,

    hasCountryAndLangaugeChange: (state) => state.hasCountryAndLangaugeChange,
    
    isMobile: (state) => state.isMobile,
    isShowHeader: (state) => state.isShowHeader,
    isShowAppDownloadHome: (state) => state.isShowAppDownloadHome,

    isLogin: (state) => state.isLogin,
    userInfo: (state) => state.userInfo,
    userRegistrationDate: (state) =>
      moment(state.userInfo.registrationDate, [
        "YYYY-MM-DD",
        "DD-MM-YYYY",
      ]).format("D/M/YYYY"),
    userLangConfig: (state) => state.userLangConfig ??= JSON.parse(localStorage.getItem(USER_LANG)),
    userSelectedLangConfigObj: (state) => state.userSelectedLangConfigObj,
    userCountryCode: (state) => state.userCountryCode,// ?? localStorage.getItem(USER_COUNTRY_CODE),

    teamInfo: (state) => state.teamInfo,
    playerInfo: (state) => state.playerInfo,
    footerTabs: (state) => state.footerTabs,
    headerTabs: (state) => state.headerTabs,
    brazilHeaderTabs: (state) => state.brazilHeaderTabs,
    currentSubNavimenu: (state) => state.currentSubNavimenu,
    currentMobileNaviMenu: (state) => state.currentMobileNaviMenu,

    commentList: (state) => state.commentList,
    commentListInfo: (state) => state.commentListInfo,
    firstCommentList: (state) => state.firstCommentList,

    popUpSignInModule: (state) => state.popUpSignInModule,
    toastr: (state) => state.toastr,
    toastrIconWithBg: (state) => state.toastrIconWithBg,
    toastrFav: (state) => state.toastrFav,
    favLoginTips: (state) => state.favLoginTips,
    matchKnockOutsList: (state) => state.matchKnockOutsList,
    isJoinedEvent: (state) => state.isJoinedEvent,
    isEventEnded: (state) => state.isEventEnded,
    knockOutLive: (state) => state.knockOutLive,

    isShowStickyBanner: (state) => state.isShowStickyBanner,
    isShowFavModal: (state) => state.isShowFavModal,
    isShowBasketballFavModal: (state) => state.isShowBasketballFavModal,
    isFavModelChange: (state) => state.isFavModelChange,

    hasNewNotification: (state) => state.hasNewNotification,
    newNotificationData: (state) => state.newNotificationData,
  },

  actions: {
    setMetadataProvider({ commit }, params) {
      commit("METADATA_PROVIDER", params);
    },
    isShowStickyBannerEvent({ commit }, params) {
      commit("STICKY_BANNER_EVENT", params);
    },
    toggleFavModal({ commit }, params) {
      commit("IS_SHOW_FAV_MODAL", params);
    },
    toggleBasketballFavModal({ commit }, params) {
      commit("IS_SHOW_BASKETBALL_FAV_MODAL", params);
    },
    toggleFavModalList({ commit }, params) {
      commit("FAV_MODAL_CHANGE_EVENT", params);
    },
    toggleNewNotification({ commit }, params) {
      commit("HAS_NEW_NOTIFICATION_CHANGE_EVENT", params);
    },
    toggleCountryAndLanguage({ commit }, params) {
      commit("HAS_COUNTRY_AND_LANGAUGE_CHANGE_EVENT", params);
    },
    defineSportType({ commit }, params) {
      commit("DEFINED_CURRENT_SPORTTYPE", params);
    },
    
    // matchListing
    isOddsMenuToggle({ commit }, params) {
      commit("MATCHODDSMENU", params);
    },
    isOddsMoreToggle({ commit }, params) {
      commit("MATCHODDSMORE", params);
    },
    oddsOptionChangeEvent({ commit }, params) {
      commit("MATCHODDS_OPTION_HANDLER", params);
    },
    isSameToggle({ commit }, params) {
      commit("MATCHODDS_ISSAME_HANDLER", params);
    },
    oddsLeagueListHandler({ commit }, params) {
      commit("MATCHODDS_LEAGUE_HANDLER", params);
    },
    // matchListing End
    refresh({ commit }, params) {
      commit("REFRESH", params);
    },
    isRedirect({ commit }, params) {
      commit("IS_REDIRECT", params);
    },
    isHasLive({ commit }, params) {
      commit("IS_HAS_LIVE", params);
    },
    componentKey({ commit }, params) {
      commit("COMPONENT_KEY", params);
    },
    homePageLeaugeParamsChangeEvent({ commit }, params) {
      commit("HOME_PAGE_LEAGUE_PARAMS_CHANGE_EVENT", params);
    },
    isShowHeaderEvent({ commit }, params) {
      commit("IS_SHOW_HEADER", params);
    },
    isShowAppDownloadHomePageEvent({ commit }, params) {
      commit("IS_SHOW_APP_DOWNLOAD_HOMEPAGE", params);
    },
    isLoginEvent({ commit }, params) {
      commit("IS_LOGIN", params);
    },
    commonVerifyLogin({ commit }, params) {
      commit("COMMON_VERIFY_LOGIN", params);
    },
    logoutEvent({ commit }, params) {
      commit("LOGOUT", params);
    },
    navigationMenuEvent({ commit }, params) {
      commit("NAVIGATION_MENU_EVENT", params);
    },
    setUserData({ commit }, params) {
      commit("SET_USER_DATA", params);
    },
    buildCountryBasedLangConfigUi({ dispatch, commit, state }, params) {
      commit("COUNTRY_CODE_TO_LANG_OBJ_LIST", params);
      commit("UPDATE_USER_SELECTED_LANG_CONFIG_OBJ");
      if (!state.currentCountryObj.supportedLocale.includes(state.currentLocale)) {
        dispatch("currentLocaleChangeEvent", state.userSelectedLangConfigObj.locale);;
      } else {
        
      }
    },
    // countryCodeToLangObjList({ commit }, params) {
    //   commit("COUNTRY_CODE_TO_LANG_OBJ_LIST", params);
    // },
    // updateUserLangConfig({ commit }, params) {
    //   commit("UPDATE_USER_LANG_CONFIG", params);
    // },
    // updateUserSelectedLangConfigObj({ commit }, params) {
    //   commit("UPDATE_USER_SELECTED_LANG_CONFIG_OBJ", params);
    // },
    currentCountryLocaleChangeEvent({ commit },params) {
      commit("CURRENT_COUNTRY_LOCALE_CHANGE_EVENT", params)
    },
    currentCountryChangeEvent({ commit }, params) {
      commit("CURRENT_COUNTRY_CHANGE_EVENT", params);
    },
    currentLocaleChangeEvent({ commit }, params) {
      commit("CURRENT_LOCALE_CHANGE_EVENT", params);
    },
    deviceChangeSizeEvent({ commit }, params) {
      commit("DEVICE_CHANGE_SIZE_EVENT", params);
    },
    popUpSignInModuleEvent({ commit }, params) {
      commit("POPUP_SIGN_IN_MODULE_EVENT", params);
    },
    setCurrentPathName({ commit }, params) {
      commit("SET_CURRENT_PATH_NAME", params);
    },
    //toastr
    toastrChangeEvent({ commit }, params) {
      commit("TOASTR_CHANGE_EVENT", params);
    },
    toastrIconWithBgChangeEvent({ commit }, params) {
      commit("TOASTR_ICON_WITH_BG_CHANGE_EVENT", params);
    },
    toastrFavChangeEvent({ commit }, params) {
      commit("TOASTR_FAV_CHANGE_EVENT", params);
    },
    //favLoginTips
    favLoginTipsChangeEvent({ commit }, params) {
      commit("FAV_LOGIN_TIPS_CHANGE_EVENT", params);
    },
    
    //event
    handleKnockOutList({ commit }, params) {
      commit("KNOCKOUT_LIST_EVENT", params);
    },
    handleContestJoinEvent({ commit }, params) {
      commit("CONTEST_IS_JOIN_EVENT", params);
    },
    handleKnockOutEvent({ commit }, params) {
      commit("KNOCKOUT_LIVE_EVENT", params);
    },
    handleContestEndEvent({ commit }, params) {
      commit("CONTEST_IS_END_EVENT", params);
    },

    //comment
    commentListEvent({ commit }, params) {
      commit("COMMENT_LIST_EVENT", params);
    },

    commentListChildEvent({ commit }, params) {
      commit("COMMENT_LIST_CHILD_EVENT", params);
    },
    commentListPushEvent({ commit }, params) {
      commit("COMMENT_LIST_PUSH_EVENT", params);
    },

    commentListInfoEvent({ commit }, params) {
      commit("COMMENT_LIST_INFO", params);
    },

    updateMatchDetailScore({ commit }, params) {
      commit("SUCUESS_GET_MATCH_DETAIL_SCORE", params);
    },
    getLeagueList({ commit }, params) {
      if (typeof params === "undefined") {
        params = {};
      }
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/soccerLeague/leagueList`, params);
    },
    getLeagueListFav({ commit }, params) {
      if (typeof params === "undefined") {
        params = {};
      }
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/league/all`, params);
    },
    getMatchList({ commit }, params) {
      if (typeof params === "undefined") {
        params = {};
      }
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      api
        .get(`/match/getMatchList`, params)
        .then((data) => {
          commit("SUCUESS_GET_MATCH_LIST", data);
        })
        .catch((res) => {
          return "";
        });
    },
    getMatchListv4({ commit }, params) {
      return api.get(`/match/getMatchListv4`, params);
    },
    getH2hInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/h2h/info`, params);
    },
    getMatchDetailScore({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      commit("MATCH_ID", params.matchId);
      return api
        .get(`/match/score`, params)
        .then((data) => {
          commit("SUCUESS_GET_MATCH_DETAIL_SCORE", data.result);
        })
        .catch((res) => {
          return "";
        });
    },

    // getMatchDetailScoreAsync({ commit }, params) {
    //   params.token = this.getters.userInfo.token;
    //   params.lang = this.getters.currentLanguageObj.apiParam;
    //   return api.get(`/match/score`, params);
    // },
    getFixtureByLeague({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/getFixtureByLeague`, params);
    },
    getMatchListAsync({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/match/getMatchList`, params);
      //return api.get(`/match/getMatchList`, params);
    },
    getMatchListAsync2({ commit }, params) {
      //params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/getMatchListv2`, params);
    },
    getMatchListAsync4({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/match/getMatchListv4`, params);
    },
    getMatchListByTeam({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/getMatchListByTeam`, params);
    },
    getPastGoalRate({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/event/pastGoalRate`, params);
    },
    getLatestThreePeriodOdds({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/odds/latestThreePeriod`, params);
    },
    getMatchListv2WCAsync({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/getMatchListv2WC`, params);
    },
    getMatchListv2AFCAsync({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/afc_2024/getMatchListv4`, params);
    },
    getMatchListv2AFCONCAsync({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/afcon_2024/getMatchListv4`, params);
    },

    //start match detail api
    getLivestreamAdInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/ad/getAdvertisement`, params);
    },
    postUpdateAdViewedTimes({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post(`/ad/updateAdUserViewed`, params);
    },
    getMatchDetailScoreAsync({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/score`, params);
    },
    getMatchDetailScoreWinRate({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/winRate`, params);
    },
    getMatchDetailScoreLatestEuropeOdds({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/odds/latestEuropeOdds`, params);
    },
    getMatchDetaiHighlightList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/match/getHighlightList`, params);
    },
    getMatchDetaiInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/info`, params);
    },
    getMatchDetaiEvent({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/match/gs_event`, params);
    },
    getMatchDetailLiveTrackerUrl({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/match/getLiveTrackerUrl`, params);
    },
    getMatchDetailCommentary({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/match/commentary`, params);
    },
    getMatchDetailStat({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/match/stat`, params);
    },
    getMatchDetailLineUp({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/match/lineup`, params);
    },
    // end match detail api

    // match listing api
    getLeagueOdds({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.matchId = params.matchId.matchId;
      if (params.side === "h2h") {
        return api.get(`/h2h/odds`, params);
      } else {
        return api.get(`/last/${params.side}`, params);
      }
    },
    getGoalScoringStats({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/stat/goal`, params);
    },
    getSquadStatus({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/analysis/getSquadStatus`, params);
    },
    getPerformance({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/player/getPerformance`, params);
    },
    getLeagueStanding({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(
        `/soccer/getLeagueStandingStatByMatch`,
        params
      );
    },
    getNextFiveMatch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/nextFiveMatch`, params);
    },
    getLastNMatches({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(
        `/match/analysis/getTeamStatComparison`,
        params
      );
    },
    getPreMatchReport({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/analysis/getMatchTips`, params);
    },
    getMediaPredict({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/analysis/getMediaTips`, params);
    },
    getCurrentSeasonStat({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(
        `/match/analysis/getCurrentSeasonStatByMatch`,
        params
      );
    },
    getOddsHandicap({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/odds/handicap`, params);
    },
    getOddsOverUnder({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/odds/overUnder`, params);
    },
    getEuropeOdds({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/odds/europeOdds`, params);
    },
    // end match listing api

    getSoccerLeagueRanking({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/soccerLeague/ranking`, params);
    },

    getPastSoccerLeagueRanking({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/soccerLeague/ranking_history`, params)
    },
    getSoccerSubLeagueList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/soccerLeague/subLeagueList`, params);
    },
    getSeasonListByLeagueId({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/match/getSeasonListByLeagueId`, params);
    },

    getTeamInfo({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.token = this.getters.userInfo.token;
      return api
        .get(`/team/info`, params)
        .then((data) => {
          commit("SUCUESS_GET_TEAM_INFO", data.result);
        })
        .catch((res) => {
          return "";
        });
    },
    getPlayerInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api
        .get(`/player/info`, params)
        .then((data) => {
          commit("SUCUESS_GET_PLAYER_INFO", data.result);
        })
        .catch((res) => {
          return "";
        });
    },

    getHighlightList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/highlight/highlightList`, params);
    },

    getNewsLetterSubscribe({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get("/newsLetter/subscribe", params);
    },

    getFocusPictureFindPicture({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/focusPicture/findPicture`, params);
    },

    //news
    getAllNewsList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/news/getAllNewsList`, params);
    },
    getNewsDetail({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/news/getNewsDetail`, params);
    },
    getNewsListByAuthor({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.language = this.getters.currentLanguageObj.locale;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/news/getNewsListByAuthor`, params);
    },
    getNewsListByCategory({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/news/getNewsListByCategory`, params);
    },
    getNewsListByTag({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/news/getNewsListByTag`, params);
    },

    //signup
    getWSUP({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.v3 = true;
      return api.post(`/user/getWSUP`, params);
    },
    postSignup({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/signup", params);
    },
    postFirebaseSignup({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/user/firebase/signup`, params);
    },
    postFirebaseSignin({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/user/firebase/login`, params);
    },
    postFirebaseForgotPassword({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(
        `/user/firebase/forgotPassword/changePassword`,
        params
      );
    },
    postFirebaseForgotPasswordValidate({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(
        `/user/firebase/forgotPassword/validate`,
        params
      );
    },
    
    postUpdateReferralCode({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/updateReferralCode", params);
    },

    getLoginMethod({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get("/user/getLoginMethod", params);
    },

    getSignUpMethod({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get("/user/getSignUpMethod", params);
    },
    //sign in
    postSignin({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/login", params);
    },

    postSigninLine({ commit }, params) {
      return api.post("/user/loginLine", params);
    },
    postSigninZalo({ commit }, params) {
      return api.post("/user/loginZalo", params);
    },
    getWLGP({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.v3 = true;
      return api.post(`/user/getWLGP`, params);
    },
    postLoginOtp({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/loginOtp", params);
    },

    //Logout
    postLogout({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/logout", params);
    },

    //forget password
    postForgotPasswordGetCode({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.v3 = true;
      return api.post("/user/forgotPassword/getCode", params);
    },

    //forget password validate
    postForgotPasswordValidate({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/forgotPassword/validate", params);
    },

    //change password
    postChangePassword({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/forgotPassword/changePassword", params);
    },

    //set password
    postSetPassword({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/setPassword", params);
    },

    //get current location info
    getCurrentLocation({ commit }, params) {
      return api.getSelfUrl("http://ip-api.com/json", params);
    },

    // delete account
    postDeleteUserAccount({ commit }, params) {
      return api.post("/user/deleteAccount", params);
    },

    //profile
    getRetrieveUserInfo({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get("/user/retrieveUserInfo", params);
    },
    postUpdateProfilePicture({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/updateProfilePicture", params);
    },
    postUpdateProfile({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/updateProfile", params);
    },
    postUpdatePassword({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/updatePassword", params);
    },
    postUpdateProfilePreferredCountry({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post("/user/updateProfilePreferredCountry", params);
    },
    postUpdateProfilePreferredLanguage({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post("/user/updateProfilePreferredLanguage", params);
    },

    //comment
    postNewsAddComment({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post(`/news/addComment`, params);
    },
    getNewsListComment({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/news/listComment`, params);
    },
    postNewsLikeComment({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post(`/news/likeComment`, params);
    },

    //event prediction
    getContentList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      // params.sportType = this._state.data.convertSportType[this.getters.currentSportType]
    
      return api.get(`/contest/getContestList`, params);
    },
    getContestInfo({ commit }, params) {
      return api.get(`/contest/getContestInfo`, params);
    },
    getTeamListByContestId({ commit }, params) {
      return api.get(`/contest/getTeamListByContestId`, params);
    },
    getContestJoin({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.token = this.getters.userInfo.token;
      return api.get(`/contest/join`, params);
    },
    getContesterPredictionList({ commit }, params={}) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get( `/contest/getContesterPredictionList`, params);
    },
    getMyPredictionList({ commit }, params = {}) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/contest/getMyPredictionList`, params);
    },
    getLatestContesterPredictionList({ commit }, params = {}) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/contest/getLatestContesterPredictionList`,params);
    },

    //event referreal
    postParticipate({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.token = this.getters.userInfo.token;
      return api.post(`/contest2/participate`, params);
    },
    postListParticipate({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/contest2/listParticipant`, params);
    },
    postMyParticipate({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/contest2/myParticipant`, params);
    },

    //event world cup
    getLatestPredictionList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/contest4/latestPredictionList`, params);
    },
    getMyPrediction({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/contest4/myPrediction`, params);
    },
    postSubmitPrediction({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.token = this.getters.userInfo.token;
      return api.post(`/contest4/submitPrediction`, params);
    },
    getKnockout({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/afc_2024/knockout`, params);
      //return api.get(`/contest4/knockout`, params);
    },
    getAFCONKnockout({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/match/afcon_2024/knockout`, params);
      //return api.get(`/contest4/knockout`, params);
    },
    getSelections({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/contest4/selections`, params);
    },

    // User Favourite
    getFavMatchList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/fav/favMatchList`, params);
    },
    getFavLeagueList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/fav/favLeagueList`, params);
    },
    getFavTeamList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/fav/favTeamList`, params);
    },
    
    getCompetitionPopular({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/competition/popular`, params);
    },
    getTeamPopular({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/team/popular`, params);
    },
    getCountrySearch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/country/search`, params);
    },
    getCompetitionSearch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/competition/search`, params);
    },
    getTeamSearch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/team/search`, params);
    },
    getGlobalSearch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/global/search`, params);
    },
    addFavMatch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post(`/fav/favMatch`, params);
    },
    addFavTeam({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/fav/favTeam`, params);
    },
    addFavLeague({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/fav/favLeague`, params);
    },
    unFavMatch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/fav/unFavMatch`, params);
    },
    unFavTeam({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/fav/unFavTeam`, params);
    },
    unFavLeague({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/fav/unFavLeague`, params);
    },
    getFavGlobalPopular({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/global/popular`, params);
    },
    addWalkthrough({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.post(`/fav/walkthrough`,  params);
    },
    getNotificationList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/notification/list`,  params);
    },
    getNotificationAnnouncement({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/notification/announcement`,  params);
    },
    getNotificationRead({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/notification/read`,  params);
    },
    
    getNotificationNotified({ commit }, params = {}) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/notification/notified`,  params);
    },
    
    getTop10Leagues({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/competition/sidebar`, params);
    },

    // country & language selection
    getCountryCodeByIp({ commit }, params = {}) {
      params.token = this.getters.userInfo.token;
      return api.get(`/ip`, params);
    },
    
    //hkfc
    getHkfcMatchLiveVideos({ commit }, params) {
      return api.get(`/hkfc/match/liveVideos`, params);
    },
    getHKFCMatchListAsync2({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/hkfc/match/getMatchList`, params);
    },
    getHKFCSoccerLeagueRanking({ commit }, params = {}) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/hkfc/soccerLeague/ranking`, params);
    },
    getHKFCMatchKnockout({ commit }, params = {}) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/hkfc/match/knockout`, params);
    },
    getHKFCMatchDetailScoreAsync({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/hkfc/match/score`, params);
    },
    getHKFCMatchDetaiInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/hkfc/match/info`, params);
    },
    getHKFCMatchDetaiEvent({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/hkfc/match/event`, params);
    },
    getHKFCMatchDetailLineUp({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/hkfc/match/lineup`, params);
    },
    getNotificationSetting({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/notification/setting/init`, params);
    },
    postUpdateNotiSetting({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.post(`/notification/setting/update`, params);
      // notification/setting/update?token={token}&type={type} 
    },
    getProviderToken({ commit }, params ={}) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.token = this.getters.userInfo.token;
      return api.get(`/user/getProviderToken`, params);
    },

    // start basketball api
    getBasketballCountryPopular({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/country/popular`, params);
    },
    getBasketballCompetitionPopular({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/competition/popular`, params);
    },
    getBasketballTeamPopular({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/team/popular`, params);
    },
    getBasketballCountrySearch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/country/search`, params);
    },
    getBasketballCompetitionSearch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/competition/search`, params);
    },
    getBasketballTeamSearch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/team/search`, params);
    },
    getBasketballGlobalPopular({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/global/popular`, params);
    },
    getBasketballGlobalSearch({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/global/search`, params);
    },
    getBasketballMatchList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/basketball/match/getMatchList`, params);
    },
    getBasketballFixtureByLeague({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/match/getFixtureByLeague`, params);
    },

    getBasketballMatchDetailScore({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/match/score`, params);
    },
    getBasketballMatchDetaiInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/match/info`, params);
    },
    getBasketballMatchDetailEvent({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/basketball/match/event`, params);
    },
    getBasketballMatchDetaiHighlightList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/basketball/match/getHighlightList`, params);
    },
    getBasketballMatchDetailStat({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/basketball/match/stat`, params);
    },
    getBasketballMatchDetailCommentary({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/basketball/match/commentary`, params);
    },
    getBasketballH2hInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/h2h/info`, params);
    },
    getBasketballBoxScore({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/match/boxScore`, params);
    },
    getBasketballHighlightList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/highlight/highlightList`, params);
    },
    getBasketballLeagueList({ commit }, params) {
      if (typeof params === "undefined") {
        params = {};
      }
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/league/leagueList`, params);
    },
    getBasketballMatchDetaiHighlightList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/basketball/match/getHighlightList`, params);
    },
    getBasketballHighlightList({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/highlight/highlightList`, params);
    },
    getBasketballLeagueRanking({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/league/ranking`, params);
    },
    getPastBasketballLeagueRanking({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/league/rankingHistory`, params)
    },
    getBasketballSeasonListByLeagueId({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api.get(`/basketball/match/getSeasonListByLeagueId`, params);
    },
    // getBasketballMatchDetailScore({ commit }, params) {
    //   params.token = this.getters.userInfo.token;
    //   params.lang = this.getters.currentLanguageObj.apiParam;
    //   commit("MATCH_ID", params.matchId);
    //   return api
    //     .get(`/basketball/match/score`, params)
    //     .then((data) => {
    //       commit("SUCUESS_GET_MATCH_DETAIL_SCORE", data.result);
    //     })
    //     .catch((res) => {
    //       return "";
    //     });
    // },
    getBasketballTeamInfo({ commit }, params) {
      params.lang = this.getters.currentLanguageObj.apiParam;
      params.token = this.getters.userInfo.token;
      return api
        .get(`/basketball/team/info`, params)
        .then((data) => {
          commit("SUCUESS_GET_TEAM_INFO", data.result);
        })
        .catch((res) => {
          return "";
        });
    },
    getBasketballPlayerInfo({ commit }, params) {
      params.token = this.getters.userInfo.token;
      return api
        .get(`/basketball/player/info`, params)
        .then((data) => {
          commit("SUCUESS_GET_PLAYER_INFO", data.result);
        })
        .catch((res) => {
          return "";
        });
    },
    getBasketballMatchListByTeam({ commit }, params) {
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/match/getMatchListByTeam`, params);
    },
    getBasketballLeagueListFav({ commit }, params) {
      if (typeof params === "undefined") {
        params = {};
      }
      params.token = this.getters.userInfo.token;
      params.lang = this.getters.currentLanguageObj.apiParam;
      return api.get(`/basketball/league/all`, params);
    },
    // end basketball api
  },
  modules: {},
};

function createNewState() {
  return {
    hasLive: "",
    refresh: false, //mobile footer refresh
    manualRefreshMatchDetailScore: false,
    manualRefreshMatchDetail: false,
    isRedirect: false,
    footerTabs: 
    // [
    //   {
    //     path: `/football/matches`,
    //     name: "FOOTBALL",
    //     pathName: "home",
    //     id: 1,
    //     isLive: false,
    //     selected: false,
    //     sportType: "football",
    //     children: [
    //       {
    //         path: `/fantasysport`,
    //         name: "FANTASY_SPORT",
    //         pathName: "fantasysport",
    //         id: 8,
    //         isLive: false,
    //         selected: false,
    //       },
    //       {
    //         path: `/football/highlights`,
    //         name: "HIGHLIGHTS",
    //         pathName: "footballHighlights",
    //         id: 2,
    //         isLive: false,
    //         selected: false,
    //       },
    //       {
    //         name: "SELECT_SPORT",
    //       },
    //       // {
    //       //   path: `/football/matches`,
    //       //   name: "MATCHES",
    //       //   pathName: "footballMatches",
    //       //   id: 1,
    //       //   isLive: false,
    //       //   selected: false,
    //       // },
    //       {
    //         path: `/news`,
    //         name: "NEWS",
    //         pathName: "news",
    //         id: 3,
    //         isLive: false,
    //         selected: false,
    //       },
    //       {
    //         path: `/football/event?type=ongoing`,
    //         name: "EVENTS",
    //         pathName: "footballEvent",
    //         id: 4,
    //         isLive: false,
    //         selected: false,
    //       },
    //     ]
    //   },
    //   {
    //     path: `/basketball/matches`,
    //     name: "BASKETBALL",
    //     pathName: "basketball",
    //     id: 10,
    //     isLive: false,
    //     selected: false,
    //     sportType: "basketball",
    //     children: [
    //       {
    //         path: `/fantasysport`,
    //         name: "FANTASY_SPORT",
    //         pathName: "fantasysport",
    //         id: 8,
    //         isLive: false,
    //         selected: false,
    //       },
    //       {
    //         path: `/basketball/highlights`,
    //         name: "HIGHLIGHTS",
    //         pathName: "basketballHighlights",
    //         id: 12,
    //         isLive: false,
    //         selected: false,
    //       },
    //       {
    //         name: "SELECT_SPORT",
    //       },
    //       // {
    //       //   path: `/basketball/matches`,
    //       //   name: "MATCHES",
    //       //   pathName: "basketballMatches",
    //       //   id: 11,
    //       //   isLive: false,
    //       //   selected: false,
    //       // },
    //       {
    //         path: `/news`,
    //         name: "NEWS",
    //         pathName: "news",
    //         id: 3,
    //         isLive: false,
    //         selected: false,
    //       },
    //       {
    //         path: `/basketball/event?type=ongoing`,
    //         name: "EVENTS",
    //         pathName: "basketballEvent",
    //         id: 13,
    //         isLive: false,
    //         selected: false,
    //       },
    //     ]
    //   },
    //   // {
    //   //   path: `/news`,
    //   //   name: "NEWS",
    //   //   pathName: "news",
    //   //   id: 3,
    //   //   isLive: false,
    //   //   selected: false,
    //   //   children: [
    //   //     {
    //   //       path: `/news`,
    //   //       name: "ALL",
    //   //       pathName: "allNews",
    //   //       id: 10,
    //   //       isLive: false,
    //   //       selected: false,
    //   //     },
    //   //     {
    //   //       path: `/football/news`,
    //   //       name: "FOOTBALL",
    //   //       pathName: "footballNews",
    //   //       id: 10,
    //   //       isLive: false,
    //   //       selected: false,
    //   //     },
    //   //     {
    //   //       path: `/basketball/news`,
    //   //       name: "BASKETBALL",
    //   //       pathName: "basketballNews",
    //   //       id: 10,
    //   //       isLive: false,
    //   //       selected: false,
    //   //     },
    //   //   ]
    //   // },
    // ],
    [
      {
        path: `/football/matches`,
        name: "MATCHES",
        pathOnly: "matches",
        pathName: "home",
        id: 1,
        isLive: false,
        selected: false,
      },
      {
        path: `/football/highlights`,
        name: "HIGHLIGHTS",
        pathOnly: "highlights",
        pathName: "footballHighlights",
        id: 2,
        isLive: false,
        selected: false,
      },
      // {
      //   path: `/FIFA2022`,
      //   name: "FIFA2022",
      //   pathName: "FIFA2022",
      //   id: 5,
      //   isLive: false,
      //   selected: false,
      // },
       {
        path: `/football/fantasysport`,
        name: "FANTASY",
        pathOnly: "fantasysport",
        pathName: "footballFantasysport",
        id: 9,
        isLive: false,
        selected: false,
        isExtraHeight: true
      },
      // {
      //   path: `/hkfc`,
      //   name: "HKFC_SOCCER_SEVENS",
      //   pathName: "hkfc",
      //   id: 7,
      //   isLive: false,
      //   selected: false,
      //   isExtraHeight: true,
      // },
      {
        path: `/news`,
        name: "NEWS",
        pathOnly: "news",
        pathName: "news",
        id: 3,
        isLive: false,
        selected: false,
      },
      {
        path: `/football/fantasysport`,
        name: "FANTASY",
        pathOnly: "fantasysport",
        pathName: "footballFantasysport",
        id: 8,
        isLive: false,
        selected: false,
        isExtraHeight: true
      },
      // {
      //   path: `/event?type=ongoing`,
      //   name: "EVENTS",
      //   pathName: "event",
      //   id: 4,
      //   isLive: true,
      //   selected: false,
      // },
    ],
    headerTabs: [
      {
        path: `/football/matches`,
        name: "FOOTBALL",
        pathName: "home",
        id: 1,
        isLive: false,
        selected: false,
        sportType: "football",
        children: [
          {
            path: `/football/matches`,
            name: "MATCHES",
            pathName: "footballMatches",
            id: 1,
            isLive: false,
            selected: false,
          },
          {
            path: `/football/highlights`,
            name: "HIGHLIGHTS",
            pathName: "footballHighlights",
            id: 2,
            isLive: false,
            selected: false,
          },
          {
            path: `/football/fav`,
            name: "FAVOURITES",
            pathName: "footballFavourites",
            id: 6,
            isLive: false,
            selected: false,
          },
          {
            path: `/football/event?type=ongoing`,
            name: "EVENTS",
            pathName: "footballEvent",
            id: 4,
            isLive: false,
            selected: false,
          },
          {
            path: `/football/fantasysport`,
            name: "FANTASY_SPORT",
            pathName: "fantasysport",
            id: 8,
            isLive: false,
            selected: false,
          },
          // {
          //   path: `/hkfc`,
          //   name: "HKFC_SOCCER_SEVENS",
          //   pathName: "hkfc",
          //   id: 7,
          //   isLive: false,
          //   selected: false,
          // },
          // {
          //   path: `/FIFA2022`,
          //   name: "WORLD_CUP_2022",
          //   pathName: "FIFA2022",
          //   id: 5,
          //   isLive: false,
          //   selected: false,
          // },
          // {
          //   path: `/afc2023`,
          //   name: "AFC 2023",
          //   pathName: "afc2023",
          //   id: 5,
          //   isLive: false,
          //   selected: false,
          // },
          {
            path: `/afcon2023`,
            name: "AFCON 2023",
            pathName: "afcon2023",
            id: 9,
            isLive: false,
            selected: false,
          },
        ]
      },
      {
        path: `/basketball/matches`,
        name: "BASKETBALL",
        pathName: "basketball",
        id: 10,
        isLive: false,
        selected: false,
        sportType: "basketball",
        children: [
          {
            path: `/basketball/matches`,
            name: "MATCHES",
            pathName: "basketballMatches",
            id: 11,
            isLive: false,
            selected: false,
          },
          {
            path: `/basketball/highlights`,
            name: "HIGHLIGHTS",
            pathName: "basketballHighlights",
            id: 12,
            isLive: false,
            selected: false,
          },
          // {
          //   path: `/basketball/fav`,
          //   name: "FAVOURITES",
          //   pathName: "basketballFavourites",
          //   id: 6,
          //   isLive: false,
          //   selected: false,
          // },
          {
            path: `/basketball/event?type=ongoing`,
            name: "EVENTS",
            pathName: "basketballEvent",
            id: 13,
            isLive: false,
            selected: false,
          },
          {
            path: `/basketball/fantasysport`,
            name: "FANTASY_SPORT",
            pathName: "basketballFantasysport",
            id: 17,
            isLive: false,
            selected: false,
          },
        ]
      },
      {
        path: `/news`,
        name: "NEWS",
        pathName: "news",
        id: 3,
        isLive: false,
        selected: false,
        children: [
          // {
          //   path: `/news`,
          //   name: "ALL",
          //   pathName: "allNews",
          //   id: 14,
          //   isLive: false,
          //   selected: false,
          // },
          // {
          //   path: `/football/news`,
          //   name: "FOOTBALL",
          //   pathName: "footballNews",
          //   id: 15,
          //   isLive: false,
          //   selected: false,
          // },
          // {
          //   path: `/basketball/news`,
          //   name: "BASKETBALL",
          //   pathName: "basketballNews",
          //   id: 16,
          //   isLive: false,
          //   selected: false,
          // },
        ]
      },
    ],

    brazilHeaderTabs: [
      {
        name: "MATCHES",
        pathName: "brazilFootballMatches",
        id: 1,
        isLive: false,
        selected: false,
      },
      {
        name: "STANDINGS",
        pathName: "brazilFootballStandings",
        id: 2,
        isLive: false,
        selected: false,
      },
    ],

    currentSubNavimenu: [],
    componentKey: false,
    leagueIdListRanking: [],

    homePageLeagueParams: {
      leagueIdList: [],
      date: "",
    },

    matchList: [],
    matchDetailScore: {},
    matchDetailInfo: {},
    isStopTimmer: false,
    matchId: "",
    // matchListing
    isOddsMore: false,
    isOddsMenu: false,
    homeOdds: {
      oddsOptions: {
        company: "",
        duration: "",
      },
      handicapOptions: {
        company: "",
        duration: "",
      },
      matchOptions: "",
      isSameLeague: false,
      leagueList: [],
    },
    awayOdds: {
      oddsOptions: {
        company: "",
        duration: "",
      },
      handicapOptions: {
        company: "",
        duration: "",
      },
      matchOptions: "",
      isSameLeague: false,
      leagueList: [],
    },
    h2hOdds: {
      oddsOptions: {
        company: "",
        duration: "",
      },
      handicapOptions: {
        company: "",
        duration: "",
      },
      matchOptions: "",
      isSameLeague: false,
      leagueList: [],
    },
    companyGroup: [
      { name: "IBCbet", id: 1001 },
      { name: "SBOBET", id: 31 },
      { name: "SINGBET", id: 3 },
      // { name: "MACAUSLOT", id: 1 },
      { name: "LADBROKES", id: 4 },
      { name: "SNAI", id: 7 },
      { name: "BET365", id: 8 },
      { name: "WILLIAMHILL", id: 9 },
      { name: "YSB", id: 12 },
      { name: "BETVICTOR", id: 14 },
      { name: "MANSION88", id: 17 },
      { name: "INTERWETTEN", id: 19 },
      { name: "10BET", id: 22 },
      { name: "188BET", id: 23 },
      { name: "12BET", id: 24 },
      { name: "WANBET", id: 35 },
      { name: "18BET", id: 42 },
      { name: "MANBETX", id: 45 },
      { name: "PINNACLE", id: 47 },
    ],
    // matchListing End
    convertSportType: {
      football: "SOCCER",
      basketball: "BASKETBALL"
    },
    currentSportType: "",//football
    currentSportTypeParam: "SOCCER",
 
    currentLocale: "en",
    currentCountryObj: config.countryList[0],
    currentLanguageObj: config.languageList[0],
    currentCountryList: config.countryList,
    currentLanguageList: config.languageList,
    userSwitchCountryLang: 0,
    userCountryCode: null,
    currentPathName: "",
    isMobile: true,
    isShowHeader: false,
    isShowAppDownloadHome: true,

    hasCountryAndLangaugeChange: false,
    isLogin: false,
    userInfo: {},
    userLangConfig: null,
    userSelectedLangConfigObj: null,

    teamInfo: {
      sidelined: {
        player: [],
      },
      transfers: {
        in: {
          player: [],
        },
        out: {
          player: [],
        },
      },
    },
    playerInfo: {},

    commentList: [],
    commentListInfo: {
      total: 0,
    },

    // pop signin variable
    popUpSignInModule: "signIn",

    //toastr
    toastr: {
      isOpen: false,
      type: "",
      msg: "",
      duration: null,
    },
    toastrIconWithBg: {
      isOpen: false,
      type: "",
      msg: "",
      duration: null,
    },
    toastrFav: {
      isOpen: false,
      type: "",
      msg: "",
      duration: null,
      isAddedFav: false,
    },

    //fav login tips
    favLoginTips: {
      isOpen: false,
      event: {},
      type: null,
      triggerOnParent: false,
      callBackUrl: "",
      message: "",
    },

    //knockout
    matchKnockOutsList: [],
    isJoinedEvent: false,
    isEventEnded: false,
    knockOutLive: {
      isShow: false,
      isShowIcon: false,
    },

    metadataProvider: null,

    isShowStickyBanner: true,

    hasNewNotification: false,
    newNotificationData: null,
    isShowFavModal: false,
    isShowBasketballFavModal: false,
  };
}

function createNewStoreOptions(state) {
  return { ...storeOptions, state };
}

export default function() {
  const defaultState = createNewState();
  const options = createNewStoreOptions(defaultState);

  return createStore(options);
}
