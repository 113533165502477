<template>
  <!-- <img  :src="logoSrc" alt="Vue logo" > -->
  <!-- <Header></Header> -->
  <SimpleHeader></SimpleHeader>
  <router-view :key="refresh"></router-view>
  <!-- <BottomStickyBanner v-if="!isMobile" /> -->
  <!-- <Footer v-if="isShowFooter"></Footer> -->
  <!-- <BottomStickyBanner v-if="isMobile && isShowStickyBanner" /> -->
 	<!-- <NotificationMatchCard v-if="!isMobile"/> -->
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import Header from "@/components/Header.vue";
import SimpleHeader from "@/components/header/SimpleHeader.vue";
import Footer from "@/components/Footer.vue";
import BottomStickyBanner from "@/components/modal/BottomStickyBanner.vue";
import NotificationMatchCard from "@/views/notifications/components/MatchCard.vue";

export default {
  name: "Home",
  components: {
    Header,
    SimpleHeader,
    Footer,
    BottomStickyBanner,
	NotificationMatchCard
  },
  data() {
    return {
      logoSrc: require("../assets/logo.png"),
      toastrMsg: "",
	  isShowFooter: true,
    };
  },
  watch: {
	$route: {
		deep: true,
		handler(newVal, oldVal) {
			this.verifyToShowFooter();
		}
	},
  },
  mounted() {
		this.verifyToShowFooter();
  },
  methods: {
	 ...mapActions([
      "toggleNewNotification"
    ]),
	verifyToShowFooter() {
		let route = this.$route;
		this.isShowFooter = true;
		if (this.isMobile) {
			if (route.meta) {
				if (route.meta.parent === "account") {
					this.isShowFooter = false;
				}
			}
		} else {
			this.isShowFooter = true;
		}
	}
  },
  computed: {
    ...mapGetters(["refresh", "isMobile", "isShowStickyBanner", "isLogin"]),
  },

};
</script>
<style scoped></style>
