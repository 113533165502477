<template>
	<template v-if="isLoading">
		<Skeleton :pageFromSkeleton="pageFrom"></Skeleton>
	</template>
	<template v-else>
		<div v-if="isNoData" class="notification-no-data" :class="{'notification-no-data__page' :pageFrom}">
			<img src="../../../../static/images/icons/icon_notification_empty.png">
			<p class="notification-no-data__text">{{ $t('YOU_HAVE_NO_NOTIFICATIONS') }}</p>
		</div>
		<div v-else class="relative">
			<div class="notification-container" v-for="(level1, index) in notificationList" :key="index" ref='scrollComponent'>
				<p class="notification-header__title">{{ $t(level1.title) }}</p>
				<router-link target="_blank" :to=formatUrl(level2) class="notification-card" v-for="(level2, index) in level1.list" :key="index" @click="handleCardClick(level2, $event)">
					<div class="notification-dot__wrapper">
						<div v-show="!level2.isRead" class="notification-dot"></div>
					</div>
					<div class="notification-type__left-wrapper">
						<img v-if="level2.type === 1" src="../../../../static/images/notification/ball.png" >
						<template v-else>
							<img class="notification-type__left-logo" v-if="level2.logo" :src="`${s3ImgUrl}/${level2.logo}`">
							<img v-else src="../../../../static/images/notification/ball.png">
						</template>
					</div>
					<div class="notification-content__wrapper">
						<p class="notification-content__title" :class="{'notification-content__title-new' : !level2.isRead}">{{ level2.title }}</p>
						<p class="notification-content__body" v-if="level2.type === 3" v-html="formatContent(level2)"></p>
						<p class="notification-content__body" v-else>{{ level2.content }}</p>
						<p class="notification-content__time"  :class="{'notification-content__time-new' : !level2.isRead}">{{ formatTime(level2) }}</p>
					</div>
					<div class="notification-type__right-wrapper">
						<img :src="require(`../../../../static/images/notification/${formatType(level2)}.png`)"> 
					</div>
				</router-link>
			</div>
		</div>
	</template>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from "vuex";
import config from "@/js/config.js";

import Skeleton from "@/views/notifications/components/Skeleton.vue"

const type = {
	1 : 'speaker', // Announcement
	2 : 'match',
	3 : 'match', // Match/Event
	4: 'event', // Contest
	5: 'comment', // News details part / page
	6: 'comment', // News like & comment page
}
export default {
	props: {
		pageFrom: {
			type: String,
		},
	},
    data() {
        return {
					s3ImgUrl: config.s3ImgUrl,
					isNoData: true,
					isLoading: true,

					notificationList:[],

					page: {
						pageNo: 1,
						pageSize: 30,
						totalPage: 1
					},

					isFirstTime: false,
					isLoadMore: false,
					contestType: [
						{
							type: 1,
							urlName: 'footballEventPrediction',
						},
						{
							type: 2,
							urlName: 'footballEventTopreferral',
						},
						{
							type: 3,
							urlName: 'footballEventMatchScore',
						},
						{
							type: 4,
							urlName: 'footballEventWorldCup',
						},
						{
							type: 5,
							urlName: 'footballEventTikTok',
						}
				
					],
					currentSportType: "football"
			}
    },
	components: {
		Skeleton
	},
	mounted() {
		//this.init();
	},

	watch: {
		isLogin: {
			deep: true,
			handler(newVal, oldVal) {
				if (!newVal) {
					this.$router.push({name: "home"})
				}
			}
		}
	},
	computed: {
		...mapGetters([
			"isMobile",
			"currentLocale",
			"currentLanguageObj",
			"isLogin",
		]),
	},
	methods: {
		 ...mapActions([
            "getNotificationList",
			"getNotificationRead"
        ]),

		init() {
			this.getNotificationListData();
		},

		formatTime(data) {
			return moment(data.createTime).fromNow()
		},

		formatType(data) {
			return type[data.type]
		},
		formatContent(data) {
			let content = data.content;
			let formatedContent = content;
			if (data.type === 3) {		
				let splitContent = content.split("<br>");
				let p1 = `<p class="ellipsis-2">${splitContent[0]}</p>`
				let p2 = `<p class="ellipsis-1">${splitContent[1]}</p>`
				formatedContent = p1 + p2
			}
			return formatedContent
		},
		formatUrl(data) {
			let url = {}
			
			if (data.type === 1) {
				url = {
					name: 'announcement',
					params:{ id: data.id }
				}
			} else if (data.type === 2 || data.type === 3) {
				let redirectDetail = JSON.parse(data.redirectDetails)
			
				let countrKey = redirectDetail.countryKey
				let leagueKey = redirectDetail.leagueKey
				let matchName = redirectDetail.matchName
				let matchId = redirectDetail.matchId
				url = {
					path: `/${this.currentLocale}/${this.currentSportType}/${countrKey}/${leagueKey}/
							${matchName}/
							${ matchId }`
				}
			} else if (data.type === 4) {
				let redirectDetail = JSON.parse(data.redirectDetails)
				this.contestType.forEach(ele => {
					if (parseInt(redirectDetail.contestType) === ele.type) {
						url = { name: ele.urlName, params: { contestId: redirectDetail.contestId } }
					}
				})
			} else if (data.type === 5 || data.type === 6) {
				let redirectDetail = JSON.parse(data.redirectDetails)
				let urlName = redirectDetail.title.toLowerCase().replace(/\s/g, '-');
				if (data.type === 5) {
					url = { name: "newsDetail", params: { title: urlName, id: parseInt(redirectDetail.newsId), currentSportType: this.currentSportType }}
				} else {
					// hash: "#CommentSection",
					url = { name: "newsDetailScrollTo", params: { title: urlName, id: parseInt(redirectDetail.newsId), scrollTo: "CommentSection", currentSportType: this.currentSportType }}
				}
				
			}
			return url
		},

		async handleCardClick(data, event) {
			let params = {
				id: data.id
			}
			const result = await this.getNotificationRead(params);

			data.isRead = true;
			event.stopPropagation();
		},

		async getNotificationListData(isLoadMore) {
			let lastIndex = this.notificationList.length - 1;
			
            let params = {
                size: 10,		
            }
		
            if (isLoadMore) {		
				let arrayLength = this.notificationList[lastIndex].list.length
				params.lastId = this.notificationList[lastIndex].list[arrayLength - 1].id		
			} else {
				this.isLoading = true
			}

            const result = await this.getNotificationList(params);
			
			if (isLoadMore) {
				this.notificationList[lastIndex].list = this.notificationList[lastIndex].list.concat(result.result.data.otherList);
				
			} else {
				this.notificationList = [];

				let announcementList = this.reMapNotificationList("announcement", "ANNOUNCEMENTS", result.result.data.announcementList);
				let otherList = this.reMapNotificationList("other","OTHERS", result.result.data.otherList);

				if (announcementList.list.length > 0) {
					this.notificationList.push(announcementList)
				} 
				if (otherList.list.length > 0) {
					this.notificationList.push(otherList)
				}
				//this.notificationList = []
				this.notificationList.length === 0 ? this.isNoData = true : this.isNoData = false
				setInterval(() => {     
					this.isLoading = false;
				}, 100)
			}

			let emitParams = {
				isFinishLoad: true,
				hasNext: result.result.data.hasNext
			}

			this.$emit("emitNotificationListEvent", emitParams)
       },

	   reMapNotificationList(type, title, list) {
			let params = {
				title: title,
				type: type,
				list: list
			}

			return params
	   },
			   
		loadMorePosts(){      
			this.getNotificationListData(true);       
        },
	},
}
</script>

<style scoped>
	.notification-container{
		margin-bottom: .5rem;
	}
	.notification-container:last-of-type{
		margin-bottom: 0;
	}
	.notification-dot{
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: var(--bg-selected);
	}
	.notification-dot__wrapper{
		width: 1rem;
	}
	.notification-card{
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		padding: 0.5rem 0 0.5rem 1rem;
	}
	.notification-card:hover{
		background-color: #2e3c43
	}
	.notification-card:last-of-type{
		margin-bottom: 0;
	}
	.{
		font-size: 0.875rem;
		margin-bottom: .5rem;
		color: white;
		font-weight: 700;
		padding-left: 1rem;
	}
	.notification-type__left-wrapper{
		width: 1.25rem;
		margin-right: 1.125rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.notification-type__right-wrapper{
		width: 3.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}	
	.notification-content__wrapper{
		flex: 1;
    	overflow: hidden;
	}
	.notification-content__title{
		font-size: 0.875rem;
		color: var(--color-grey-80);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-bottom: 0;
	}
	.notification-content__title-new{
		font-weight: 700;
		color: white;
	}
	.notification-content__body{
		font-size: 0.6875rem;
		color: var(--color-grey-80);
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		margin-bottom: .1rem;
	}
	.notification-content__body-2{
	    -webkit-line-clamp: 2;
	}
	.notification-content__time{
		font-size: 0.5625rem;
		color: var(--color-grey-80);
	}
	.notification-content__time-new{
		color: var(--bg-selected)
	}
	.notification-no-data{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100% - 2.2rem);
	}
	.notification-no-data__page{
		min-height: inherit;
	}
	.notification-no-data__text{
		margin-top: 1.34375rem;
		font-size: 0.875rem;
		color: var(--color-theme-2)
	}
	.notification-type__left-logo{
		width: 20px;
		height: 20px;
		object-fit: contain;
	}
	@media (max-width: 768px) {
		.notification-card{
			background-color: var(--color-theme);
			padding: .5rem 0 .5rem 1rem;
			border-radius: 0.5rem;
			margin-bottom:.5rem
		}
		.notification-card:hover{
			background-color: var(--color-theme);
		}
		.notification-header__title{
			padding-left: 0
		}
	}
</style>