import { isSSR } from "@/shared/misc";
import store from '@/store';
import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import Home from "../views/Home.vue";
import config from "@/js/config.js";
import footerLinks from "@/js/seoArticles/footer.js";
import moment from "moment";
// meta:
// id:1 matches
// id:2 highlight
// id:3 news
// id:4 event
// id:5 world cup
// id:6 favourites
// id:7 hkfc 

let defaultLocale = config.defaultLocale;
let seoArticleRoutes = [];
let iframeCountries = config.iframeCountries;
let iframeCountryRoutes = [];
for (const [lang, arr] of Object.entries(footerLinks)) {
  if (arr.length > 0) {
    arr.forEach(article => {
      seoArticleRoutes.push({
        path: encodeURI(`/:locale/${article.url}`),
        meta: { id: 1 },
        name: article.id,
        component: () => import("../views/seo/Article.vue"),
      });
    });
  }
}

for (const [country, details] of Object.entries(iframeCountries)) {
  let countryLink = details.name.toLowerCase().replace(/ /g, "-");
  iframeCountryRoutes.push(
    {
      path: `/:locale/football/${countryLink}-${details.id}/matches`,
      meta: { id: 1, countryId: details.id },
      name: `${country}FootballMatches`,
      component: () => import('../views/matchs/football/Matchs.vue'),
    },
    {
      path: `/:locale/football/${countryLink}-${details.id}/standings`,
      meta: { id: 1, countryId: details.id },
      name: `${country}FootballStandings`,
      component: () => import("../views/matchs/football/Standings.vue"),
    }
  );
}

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: (to) => {
      if (
        moment().isAfter("2022-11-11 00:00:00") &&
        moment().isBefore("2022-12-18 23:59:59")
      ) {
        return { path: `/${defaultLocale}/world-cup-2022/matches/75` };
      } else {
        return { path: `/${defaultLocale}` };
      }
    }, //`/${defaultLocale}`
  },
  {
    path: "/:locale/world-cup-2022-bracket-mobile",
    meta: { id: 2 },
    name: "FIFA2022BracketMobile",
    component: () => import("../views/fifa/BracketMobile.vue"),
  },
  {
    path: "/:locale/signup",
    meta: { id: 4 },
    name: "signup",
    component: () => import("../views/user/SignUp.vue"),
  },
  {
    path: "/:locale/signin",
    meta: { id: 4 },
    name: "signin",
    component: () => import("../views/user/SignIn.vue"),
  },
  {
    path: "/:locale/forgetpassword",
    meta: { id: 4 },
    name: "forgetpassword",
    component: () => import("../views/user/ForgetPassword.vue"),
  },
  {
    path: "/linecallback",
    name: "linecallback",
    component: () => import("../views/callback/LineCallBack.vue"),
  },
  {
    path: "/zaloCallBack",
    name: "zaloCallBack",
    component: () => import("../views/callback/ZaloCallBack.vue"),
  },
  {
    path: "/:locale",
    component: () => import("../views/Home.vue"),
    name: "generalLocaleRoute",
    children: [
      {
        path: "football",
        component: () => import("../views/matchs/football/Matchs.vue"),
      },
      {
        path: "",
        redirect: (to) => {
          if (
            moment().isAfter("2022-11-18 00:00:00") &&
            moment().isBefore("2022-12-18 23:59:59")
          ) {
            return { path: `/${to.params.locale}/world-cup-2022/matches/75` };
          } else {
            return { name: `home` };
          }
        }, //`/${defaultLocale}`
      },
      {
        path: "football/matches",
        component: () => import("../views/matchs/football/Matchs.vue"),
        name: "home",
        meta: { id: 1, title: "home", name: "home", highlightMenu: "home" },
      },
      {
        path: "football/highlights",
        meta: { id: 2, highlightMenu: "home", name: "highlight" },
        name: "footballHighlights",
        component: () => import("../views/highlights/football/Matchs.vue"),
      },
      {
        path: "basketball/matches",
        component: () => import("../views/matchs/basketball/Matchs.vue"),
        name: "basketballHome",
        meta: { id: 1, title: "basketball", name: "basketball", highlightMenu: "basketball" },
      },
      {
        path: "basketball/highlights",
        meta: { id: 2, highlightMenu: "basketball", name: "highlight"},
        name: "basketballHighlights",
        component: () => import("../views/highlights/basketball/Matchs.vue"),
      },

      //news
      {
        path: "news",
        meta: { id: 3 },
        name: "news",
        component: () => import("../views/news/News.vue"),
      },
      {
        path: "news/basketball",
        meta: { id: 3 },
        name: "basketballNews",
        component: () => import("../views/news/News.vue"),
      },
      {
        path: "news/football",
        meta: { id: 3 },
        name: "footballNews",
        component: () => import("../views/news/News.vue"),
      },
      {
        path: "news/categories/:categoryName/:categoryId",
        meta: { id: 3 },
        name: "newsCategories",
        component: () => import("../views/news/NewsCategories.vue"),
      },
      {
        path: "news/related/:categoryName/:categoryId",
        meta: { id: 3 },
        name: "newsRelated",
        component: () => import("../views/news/NewsCategories.vue"),
      },
      {
        path: "news/:currentSportType/:title/:id",
        meta: { id: 3 },
        name: "newsDetail",
        component: () => import("../views/news/NewsDetail.vue"),
      },
      {
        path: "news/:title/:id/:scrollTo",
        meta: { id: 3 },
        name: "newsDetailScrollTo",
        component: () => import("../views/news/NewsDetail.vue"),
      },
      {
        path: "news/:title/:id/bgsystem-preview",
        meta: { id: 3 },
        name: "bgPreviewNewsDetail",
        component: () => import("../views/news/NewsDetail.vue"),
      },
      {
        path: "news/author/:authorName/:id",
        meta: { id: 3 },
        name: "newsAuthor",
        component: () => import("../views/news/NewsAuthor.vue"),
      },

      {
        path: "account",
        name: "account",
        meta: { parent: "account" },
        component: () => import("../views/account/Account.vue"),
        // redirect:{ name: 'myProfile' },
      },
    
      {
        path: "account/myProfile",
        name: "myProfile",
        meta: { name: "MY_PROFILE", parent: "account" },
        component: () => import("../views/account/MyProfile.vue"),
      },
    
      {
        path: "account/changePassword",
        name: "changePassword",
        meta: { name: "CHANGE_PASSWORD", parent: "account" },
        component: () => import("../views/account/ChangePassword.vue"),
      },
      {
        path: "account/myPhoneNumber",
        name: "myPhoneNumber",
        meta: { name: "MY_PHONE_NUMBER", parent: "account" },
        component: () => import("../views/account/MyPhoneNumber.vue"),
      },
      {
        path: "account/deleteAccount",
        name: "deleteAccount",
        meta: { name: "REQUEST_ACCOUNT_DELETION", parent: "account" },
        component: () => import("../views/account/DeleteAccount.vue"),
      },
      {
        path: "account/myReferralCode",
        name: "myReferralCode",
        meta: { name: "MY_REFERRAL_CODE", parent: "account" },
        component: () => import("../views/account/MyReferralCode.vue"),
      },
    
      // favourites
      {
        path: "football/fav",
        name: "footballFavourites",
        meta: { id: 6, highlightMenu: "home" },
        component: () => import("../views/fav/Index.vue"),
        redirect: (to) => {
          return { name: "footballFavMatches" }
        },
        children: [
          {
            path: "matches",
            name: "footballFavMatches",
            component: () => import("../views/fav/Matches.vue"),
          },
          {
            path: "teams",
            name: "footballFavTeams",
            component: () => import("../views/fav/Teams.vue"),
          },
          {
            path: "competitions",
            name: "footballFavCompetitions",
            component: () => import("../views/fav/Competitions.vue"),
          },
        ],
      },

      //announcement 
      {
        path: "announcement/:id",
        name: "announcement",
        meta: { id: 7},
        component: () => import("../views/announcement/Index.vue")
      },

      //fantasy 
      {
        path: "basketball/fantasysport",
        name: "basketballFantasysport",
        meta: { id: 8, category: "basketballFantasysport", name: "fantasysport", highlightMenu: "basketball", highlightSubMenu: "basketballFantasysport"},
        component: () => import("../views/fantasy/basketball/Index.vue")
      },
      {
        path: "football/fantasysport",
        name: "footballFantasysport",
        meta: { id: 8, highlightMenu: "home" ,name: "fantasysport"},
        component: () => import("../views/fantasy/football/Index.vue")
      },  
      //notification
      {
        path: "notification",
        name: "notification",
        meta: { id: 7},
        component: () => import("../views/notifications/Index.vue")
      },
      //event
      {
        path: "football/event",
        meta: { id: 4, highlightMenu: "home" },
        name: "footballEvent",

        // query: {type: 'ongoing'},
        component: () => import("../views/event/football/Event.vue"),
      },

      {
        path: "basketball/event",
        meta: { id: 4, highlightMenu: "basketball" },
        name: "basketballEvent",

        // query: {type: 'ongoing'},
        component: () => import("../views/event/basketball/Event.vue"),
      },


      {
        path: "football/event/prediction/:contestId",
        meta: { id: 4, category: "eventDetail", highlightMenu: "home", highlightSubMenu: "footballEvent" },
        name: "footballEventPrediction",
        component: () => import("../views/event/football/Prediction.vue"),
      },
      {
        path: "football/event/worldcup/:contestId",
        meta: { id: 4, category: "eventDetail", highlightMenu: "home", highlightSubMenu: "footballEvent" },
        name: "footballEventWorldCup",
        component: () => import("../views/event/football/WorldCup.vue"),
      },
      {
        path: "football/event/tiktok/:contestId",
        meta: { id: 4, category: "eventDetail", highlightMenu: "home", highlightSubMenu: "footballEvent"},
        name: "footballEventTikTok",
        component: () => import("../views/event/football/TikTok.vue"),
      },
      {
        path: "football/event/matchscore/:contestId",
        meta: { id: 4, category: "eventDetail", highlightMenu: "home", highlightSubMenu: "footballEvent" },
        name: "footballEventMatchScore",
        component: () => import("../views/event/football/MatchScore.vue"),
      },
      {
        path: "football/event/topreferral/:contestId",
        meta: { id: 4, category: "eventDetail", highlightMenu: "home", highlightSubMenu: "footballEvent" },
        name: "footballEventTopreferral",
        component: () => import("../views/event/football/Topreferral.vue"),
      },

      {
        path: "basketball/event",
        meta: { id: 4, highlightMenu: "basketball" },
        name: "basketballEvent",

        // query: {type: 'ongoing'},
        component: () => import("../views/event/basketball/Event.vue"),
      },
      {
        path: "basketball/event/prediction/:contestId",
        meta: { id: 4, category: "eventDetail", highlightMenu: "basketball", highlightSubMenu: "basketballEvent" },
        name: "basketballEventPrediction",
        component: () => import("../views/event/basketball/Prediction.vue"),
      },
      // {
      //   path: "basketball/event/worldcup/:contestId",
      //   meta: { id: 4, category: "eventDetail", highlightMenu: "basketball", highlightSubMenu: "basketballEvent" },
      //   name: "basketballEventWorldCup",
      //   component: () => import("../views/event/basketball/WorldCup.vue"),
      // },
      {
        path: "basketball/event/tiktok/:contestId",
        meta: { id: 4, category: "eventDetail", highlightMenu: "basketball", highlightSubMenu: "basketballEvent"},
        name: "basketballEventTikTok",
        component: () => import("../views/event/basketball/TikTok.vue"),
      },
      {
        path: "basketball/event/matchscore/:contestId",
        meta: { id: 4, category: "eventDetail", highlightMenu: "basketball", highlightSubMenu: "basketballEvent" },
        name: "basketballEventMatchScore",
        component: () => import("../views/event/basketball/MatchScore.vue"),
      },
      {
        path: "basketball/event/topreferral/:contestId",
        meta: { id: 4, category: "eventDetail", highlightMenu: "basketball", highlightSubMenu: "basketballEvent" },
        name: "basketballEventTopreferral",
        component: () => import("../views/event/basketball/Topreferral.vue"),
      },

      //FIFA 2022
      // {
      //   path: "world-cup-2022",
      //   meta: { id: 5 },
      //   name: "FIFA2022",
      //   redirect: { name: "FIFA2022Matches", params: { leagueId: 75 } },
      //   component: () => import("../views/fifa/Index.vue"),
      //   children: [
      //     {
      //       path: "matches/:leagueId",
      //       name: "FIFA2022Matches",
      //       component: () => import("../views/fifa/Match.vue")
      //     },
      //     {
      //       path: "standings/:leagueId",
      //       name: "FIFA2022Standings",
      //       component: () => import("../views/matchs/football/Standings.vue")
      //     },
      //     {
      //       path: "bracket/:leagueId",
      //       name: "FIFA2022Bracket",
      //       component: () => import("../views/fifa/Bracket.vue")
      //     },
      //   ]
      // },
      // AFC, AFCON 2023
      // {
      //   path: "afc2023",
      //   meta: { id: 5, highlightMenu: "home" },
      //   name: "afc2023",
      //   redirect: { name: "afc2023Matches", params: { leagueId: 95 } },
      //   component: () => import("../views/footballEvents/afc2023/Index.vue"),
      //   children: [
      //     {
      //       path: "matches/:leagueId",
      //       name: "afc2023Matches",
      //       component: () => import("../views/footballEvents/afc2023/Match.vue")
      //     },
      //     {
      //       path: "standings/:leagueId",
      //       name: "afc2023Standings",
      //       component: () => import("../views/matchs/football/Standings.vue")
      //     },
      //     {
      //       path: "bracket/:leagueId",
      //       name: "afc2023Bracket",
      //       component: () => import("../views/footballEvents/afc2023/Bracket.vue")
      //     },
      //   ]
      // },
      // {
      //   path: "afcon2023",
      //   meta: { id: 9, highlightMenu: "home" },
      //   name: "afcon2023",
      //   redirect: { name: "afcon2023Matches", params: { leagueId: 93 } },
      //   component: () => import("../views/footballEvents/afcon2023/Index.vue"),
      //   children: [
      //     {
      //       path: "matches/:leagueId",
      //       name: "afcon2023Matches",
      //       component: () => import("../views/footballEvents/afcon2023/Match.vue")
      //     },
      //     {
      //       path: "standings/:leagueId",
      //       name: "afcon2023Standings",
      //       component: () => import("../views/matchs/football/Standings.vue")
      //     },
      //     {
      //       path: "bracket/:leagueId",
      //       name: "afcon2023Bracket",
      //       component: () => import("../views/footballEvents/afcon2023/Bracket.vue")
      //     },
      //   ]
      // },
      // //hkfc
      // {
      //   path: "hkfc",
      //   meta: { id: 7 },
      //   name: "hkfc",
      //   redirect: { name: "hkfcMatches" },
      //   component: () => import("../views/hkfc/Index.vue"),
      //   children: [
      //     {
      //       path: "matches",
      //       name: "hkfcMatches",
      //       component: () => import("../views/hkfc/Match.vue")
      //     },
      //     {
      //       path: "standing",
      //       name: "hkfcStanding",
      //       component: () => import("../views/hkfc/Standing.vue")
      //     },
      //     {
      //       path: "bracket",
      //       name: "hkfcBracket",
      //       component: () => import("../views/hkfc/Bracket.vue")
      //     },
          
      //   ]
      // },
      // {
      //   path: "football/hkfc/:country/:club/:opponent/:matchId",
      //   component: () => import("../views/hkfc/matchDetail/Index.vue"),
      //   meta: { id: 7, category: "match detail", parent: "matchDetail" },
      //   redirect: { name: "hkfcINFO" },
      //   props: true,
      //   children: [
      //     {
      //       path: "info",
      //       name: "hkfcINFO",
      //       component: () => import("../views/hkfc/matchDetail/Info.vue"),
      //     },
      //     {
      //       path: "lineups",
      //       name: "hkfcLINEUPS",
      //       component: () => import("../views/hkfc/matchDetail/LineUps.vue"),
      //     }
      //   ],
      // },
      {
        path: "football/highlights/:country/:club/:opponent/:matchId",
        meta: { id: 2, category: "highlightsDetail", highlightMenu: "home", highlightSubMenu: "footballHighlights" },
        name: "footballHighlightsDetail",
        component: () => import("../views/highlights/football/HighlightsDetail.vue"),
      },
      {
        path: "football/:country/:leagueKey",
        meta: { id: 1, category: "leaguePage", highlightMenu: "home", highlightSubMenu: "footballMatches" },
        component: () => import("../views/matchs/football/League.vue"),
        children: [
          {
            path: "matches",
            component: () => import("../views/matchs/league/football/Matches.vue"),
          },
          {
            path: "table",
            component: () => import("../views/matchs/league/football/Table.vue"),
          },
          {
            path: "about",
            component: () => import("../views/matchs/league/football/About.vue"),
          },
        ],
      },
      {
        path: "basketball/highlights/:country/:club/:opponent/:matchId",
        meta: { id: 2, category: "highlightsDetail", highlightMenu: "basketball", highlightSubMenu: "basketballHighlights" },
        name: "basketballHighlightsDetail",
        component: () => import("../views/highlights/basketball/HighlightsDetail.vue"),
      },
      {
        path: "basketball/:country/:leagueKey",
        meta: { id: 1, category: "leaguePage", highlightMenu: "basketball", highlightSubMenu: "basketballMatches" },
        component: () => import("../views/matchs/basketball/League.vue"),
        children: [
          {
            path: "matches",
            component: () => import("../views/matchs/league/basketball/Matches.vue"),
          },
          {
            path: "table",
            component: () => import("../views/matchs/league/basketball/Table.vue"),
          },
          {
            path: "about",
            component: () => import("../views/matchs/league/basketball/About.vue"),
          },
        ],
      },
      {
        path: "/:locale/privacy-policy",
        meta: { id: 4 },
        name: "privacypolicy",
        component: () => import("../views/other/PrivacyPolicy.vue"),
      },
      {
        path: "football/:country/:club/:opponent/:matchId",
        component: () => import("../views/matchs/matchDetail/football/Index.vue"),
        name: "footballMatchDetail",
        meta: { id: 1, category: "match detail", parent: "matchDetail", highlightMenu: "home", highlightSubMenu: "footballMatches" },
        redirect: { name: "FOOTBALL_INFO" },
        props: true,
        children: [
          {
            path: "info",
            name: "FOOTBALL_INFO",
            component: () => import("../views/matchs/matchDetail/football/Info.vue"),
          },
          {
            // Fav module - YIM: previously the page with both tracker and commentary, and now only left commentary
            path: "tracker",//tracker
            name: "FOOTBALL_FEED",//TRACKER    COMMENTARY
            component: () => import("../views/matchs/matchDetail/football/Tracker.vue"),
          },
          {
            path: "lineups",
            name: "FOOTBALL_LINEUPS",
            component: () => import("../views/matchs/matchDetail/football/LineUps.vue"),
          },
          {
            path: "h2h",
            name: "FOOTBALL_H2H",
            component: () => import("../views/matchs/matchDetail/football/H2H.vue"),
          },
          {
            path: "table",
            component: () => import("../views/matchs/matchDetail/football/Table.vue"),
          },
          {
            path: "stats",
            name: "FOOTBALL_STATS",
            component: () => import("../views/matchs/matchDetail/football/Stats.vue"),
          },
          {
            path: "analysis",
            name: "FOOTBALL_ANALYSIS",
            component: () => import("../views/matchs/matchDetail/football/matchListing/Analysis.vue"),
            redirect: { name: "footballStatsAnalysis" },
            children: [
              { 
                path: "details",//analysis
                name: "footballStatsAnalysis",
                component: () =>
                  import(
                    "../views/matchs/matchDetail/football/matchListing/AnalysisDetails.vue"
                  ),
                children: [
                  {
                    path: "odds/:side",
                    name: "oddsResult",
                    component: () =>
                      import(
                        "../views/matchs/matchDetail/football/matchListing/mobileOdds/OddsResult.vue"
                      ),
                    children: [
                      {
                        path: "filter",
                        name: "oddsResultFilter",
                        component: () =>
                          import(
                            "../views/matchs/matchDetail/football/matchListing/mobileOdds/OddsFilter.vue"
                          ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "handicap",
                name: "footballStatsHandicap",
                component: () =>
                  import(
                    "../views/matchs/matchDetail/football/matchListing/Handicap.vue"
                  ),
              },
              {
                path: "goals",
                name: "footballStatsGoals",
                component: () =>
                  import("../views/matchs/matchDetail/football/matchListing/Goals.vue"),
              },
              {
                path: "wdl",
                name: "footballStatsWDL",
                component: () =>
                  import(
                    "../views/matchs/matchDetail/football/matchListing/WinDrawLose.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "football/team/:teamName/:teamKey",
        component: () => import("../views/matchs/team/football/Index.vue"),
        redirect: { name: "footballTeamOverview" },
        meta: { id: 1, category: "teamPage", highlightMenu: "home", highlightSubMenu: "footballMatches" },
        children: [
          {
            path: "overview",
            name: "footballTeamOverview",
            component: () => import("../views/matchs/team/football/Overview.vue"),
          },
          {
            path: "match",
            name: "footballTeamMatch",
            component: () => import("../views/matchs/team/football/Match.vue"),
          },
          {
            path: "squad",
            name: "footballTeamSquad",
            component: () => import("../views/matchs/team/football/Squad.vue"),
          },
          // {
          //   path: "about",
          //   name: "teamAbout",
          //   component: () => import("../views/matchs/team/football/About.vue"),
          // },
        ],
      },
      {
        path: "football/player/:playerName/:player",
        meta: { id: 1, category: "playerPage", parent: "player", highlightMenu: "home", highlightSubMenu: "footballMatches" },
        component: () => import("../views/matchs/player/football/Index.vue"),
        redirect: { name: "footballPlayerOverview" },
        children: [
          {
            path: "overview",
            name: "footballPlayerOverview",
            component: () => import("../views/matchs/player/football/Overview.vue"),
          },
          // { // YIM - AS-1041
          //   path: "career",
          //   name: "footballPlayerCarrer",
          //   component: () => import("../views/matchs/player/football/Career.vue"),
          // },
          {
            path: "transfer",
            name: "footballPlayerTransfer",
            component: () => import("../views/matchs/player/football/Transfer.vue"),
          },
        ],
      },
      {
        path: "basketball/:country/:club/:opponent/:matchId",
        component: () => import("../views/matchs/matchDetail/basketball/Index.vue"),
        name: "basketballMatchDetail",
        meta: { id: 1, category: "match detail", parent: "matchDetail", highlightMenu: "basketball", highlightSubMenu: "basketballMatches" },
        redirect: { name: "BASKETBALL_INFO" },
        props: true,
        children: [
          {
            path: "info",
            name: "BASKETBALL_INFO",
            component: () => import("../views/matchs/matchDetail/basketball/Info.vue"),
          },
          {
            path: "box-score",
            name: "BASKETBALL_BOX_SCORE",
            component: () => import("../views/matchs/matchDetail/basketball/BoxScore.vue"),
          },
          {
            // Fav module - YIM: previously the page with both tracker and commentary, and now only left commentary
            path: "tracker",//tracker
            name: "BASKETBALL_FEED",//TRACKER    COMMENTARY
            component: () => import("../views/matchs/matchDetail/basketball/Tracker.vue"),
          },
          {
            path: "lineups",
            name: "BASKETBALL_LINEUPS",
            component: () => import("../views/matchs/matchDetail/basketball/LineUps.vue"),
          },
          {
            path: "h2h",
            name: "BASKETBALL_H2H",
            component: () => import("../views/matchs/matchDetail/basketball/H2H.vue"),
          },
          {
            path: "table",
            component: () => import("../views/matchs/matchDetail/basketball/Table.vue"),
          },
          {
            path: "stats",
            name: "BASKETBALL_STATS",
            component: () => import("../views/matchs/matchDetail/basketball/Stats.vue"),
          },
          {
            path: "analysis",
            name: "BASKETBALL_ANALYSIS",
            component: () => import("../views/matchs/matchDetail/basketball/matchListing/Analysis.vue"),
            redirect: { name: "basketballStatsAnalysis" },
            children: [
              { 
                path: "details",//analysis
                name: "basketballStatsAnalysis",
                component: () =>
                  import(
                    "../views/matchs/matchDetail/basketball/matchListing/AnalysisDetails.vue"
                  ),
                children: [
                  {
                    path: "odds/:side",
                    name: "oddsResult",
                    component: () =>
                      import(
                        "../views/matchs/matchDetail/basketball/matchListing/mobileOdds/OddsResult.vue"
                      ),
                    children: [
                      {
                        path: "filter",
                        name: "oddsResultFilter",
                        component: () =>
                          import(
                            "../views/matchs/matchDetail/basketball/matchListing/mobileOdds/OddsFilter.vue"
                          ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "handicap",
                name: "basketballStatsHandicap",
                component: () =>
                  import(
                    "../views/matchs/matchDetail/basketball/matchListing/Handicap.vue"
                  ),
              },
              {
                path: "goals",
                name: "basketballStatsGoals",
                component: () =>
                  import("../views/matchs/matchDetail/basketball/matchListing/Goals.vue"),
              },
              {
                path: "wdl",
                name: "basketballStatsWDL",
                component: () =>
                  import(
                    "../views/matchs/matchDetail/basketball/matchListing/WinDrawLose.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "basketball/team/:teamName/:teamKey",
        component: () => import("../views/matchs/team/basketball/Index.vue"),
        redirect: { name: "basketballTeamOverview" },
        meta: { id: 1, category: "teamPage", highlightMenu: "basketball", highlightSubMenu: "basketballMatches" },
        children: [
          {
            path: "overview",
            name: "basketballTeamOverview",
            component: () => import("../views/matchs/team/basketball/Overview.vue"),
          },
          {
            path: "match",
            name: "basketballTeamMatch",
            component: () => import("../views/matchs/team/basketball/Match.vue"),
          },
          {
            path: "squad",
            name: "basketballTeamSquad",
            component: () => import("../views/matchs/team/basketball/Squad.vue"),
          },
          // {
          //   path: "about",
          //   name: "teamAbout",
          //   component: () => import("../views/matchs/team/basketball/About.vue"),
          // },
        ],
      },
      {
        path: "basketball/player/:playerName/:player",
        meta: { id: 1, category: "playerPage", parent: "player", highlightMenu: "basketball", highlightSubMenu: "basketballMatches" },
        component: () => import("../views/matchs/player/basketball/Index.vue"),
        redirect: { name: "basketballPlayerOverview" },
        children: [
          {
            path: "overview",
            name: "basketballPlayerOverview",
            component: () => import("../views/matchs/player/basketball/Overview.vue"),
          },
          // { // YIM - AS-1041
          //   path: "career",
          //   name: "basketballPlayerCarrer",
          //   component: () => import("../views/matchs/player/basketball/Career.vue"),
          // },
          {
            path: "transfer",
            name: "basketballPlayerTransfer",
            component: () => import("../views/matchs/player/basketball/Transfer.vue"),
          },
        ],
      },
      {
        path: "/:locale(.*)",
        redirect: `/${defaultLocale}`,
      },
    ],
  },
  ...seoArticleRoutes, ...iframeCountryRoutes,
];

const router = createRouter({
  history: isSSR
    ? createMemoryHistory(process.env.BASE_URL)
    : createWebHistory(process.env.BASE_URL), //createWebHistory(process.env.BASE_URL)
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (
        from.meta.category === "match detail" &&
        to.meta.category === "match detail"
      ) {
        //desktop version match detail when switch tab save window previous position
        return savedPosition;
      // } else if (to.hash) {
      //   return new Promise((resolve, reject) => {
      //     setTimeout(() => {
      //       resolve({el: to.hash, behavior: "smooth"})
      //     }, 1500);
      //   });
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 60);
      }
    }
  },
});

export default router;
