<template>
    <MetaHeader/>
    <router-view/>
    <Toastr ref="toastrRef" :text="toastrMsg" :type="toastrType" :position="toastrPosition"></Toastr> 
    <ToastrIconWithBg></ToastrIconWithBg>
    <ToastrFav :text="toastrMsg" :type="toastrType" :position="toastrPosition"></ToastrFav> 
    <FavLoginTips ref="favLoginTipsRef" ></FavLoginTips>
   	<NotificationMatchCard v-if="!isMobile"/>
</template>


<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";
  import ResizeMixin from '@/js/mixin/handleResizeAndRouting.js'
  import Toastr from '@/components/toastr/Toastr.vue'
  import ToastrIconWithBg from '@/components/toastr/ToastrIconWithBg.vue'
  import ToastrFav from '@/components/favourite/Toastr.vue'
  import FavLoginTips from "@/components/favourite/TipsLogin.vue"
  import NotificationMatchCard from "@/views/notifications/components/MatchCard.vue";
  import config from "@/js/config.js";
  import { initializeApp, getApps } from "firebase/app";
  import { getDatabase, ref, query, orderByKey, equalTo, onValue } from "firebase/database";
  import { getAuth, signInAnonymously } from "firebase/auth";

  const USER = config.localStorageUser;
  const USER_LANG = config.localStorageUserLang;
  const USER_COUNTRY_CODE = config.localStorageUserCountryCode;

  export default {
    components: {
      Toastr,
      ToastrIconWithBg,
      ToastrFav,
      FavLoginTips,
      NotificationMatchCard
    },
    created() {
     
    },
    mounted() {
       this.commonVerifyLogin();
    },
    computed: {
      ...mapGetters([
        "userLangConfig",
        "currentCountryObj",
        // "userSelectedLangConfigObj",
        "isLogin",
        // "userCountryCode",
        "currentLanguageObj",
        "userInfo",
      ]),
    },
    data() {
      return{
          isShowHeader:true,
          toastrMsg:'',
          isHomePage: null,
          init: false
      }
    },
	watch: {
		isLogin: {
			immediate: true,
			handler(newVal,oldVal) {
				if (newVal) {
          this.REMOVE_USER_LANG_CONFIG();
					if (location.hostname !== 'localhost.testsports168.com') {
            this.$nextTick(() => {
              this.iniFirebase();
            });
					}
				}
        this.handleMultiCountyLink();
			}
		}
	},
    methods: {
      ...mapMutations([
        "REMOVE_USER_LANG_CONFIG",
        "SET_USER_COUNTRY_CODE",
      ]),
      ...mapActions([
        "commonVerifyLogin",
        "toggleNewNotification",
        "getCountryCodeByIp",
        "buildCountryBasedLangConfigUi",
        "currentCountryChangeEvent",
      ]),

      iniFirebase() {
        var firebaseConfig = {
        apiKey: config.RECAPTCHA_API_KEY,
        authDomain: config.RECAPTCHA_AUTH_DOMAIN,
        projectId: config.RECAPTCHA_PROJECT_ID,
        storageBucket: config.RECAPTCHA_STORAGE_BUCKET,
        messagingSenderId: config.RECAPTCHA_MESSAGING_SENDER_ID,
        appId: config.RECAPTCHA_APP_ID,
        databaseURL: config.FIREBASE_DATABASE_URL
        };
        
        getApps().length === 0 ? initializeApp(firebaseConfig) : getApps();
        const db = getDatabase()
        const reference = query(ref(db, 'notification'), orderByKey(), equalTo(this.userInfo.username));
        signInAnonymously(getAuth()).then(() => {
          onValue(reference, (snapshot) => {
            if (this.init && snapshot.val()) {
              this.toggleNewNotification(JSON.parse(Object.values(snapshot.val())));
            }
            if (this.isLogin) {
              this.init = true;
            }
          });
        });
      },

      verifyUserLandOnPreferredLang() {
        let preferredLangObj = this.userLangConfig.filter(obj => obj.selected)[0];
        let currentLocale = this.$route.params.locale;
        
        if (this.$route.name === "event" && this.$route.params.locale !== preferredLangObj.displayLocale) {
          window.location.replace(window.location.origin + `/${preferredLangObj.displayLocale}/event`);
        } else if (this.$route.meta.category !== "eventDetail" && preferredLangObj.displayLocale !== currentLocale) {
          //window.location.replace(window.location.origin + `/${preferredLangObj.displayLocale}`);
        }
      },

      async handleMultiCountyLink() {  
        const user = localStorage.getItem("user");

        if (user === null  && !this.userCountryCode) {
          // let result = await this.getCountryCodeByIp();
          let countryCode = "BR";//result.result.countryCode;

          if (true) {//result.message === "Success"
            this.currentCountryChangeEvent(countryCode);
            
            // i.e.: ip=th, path=/en, no update contest list
            let route = this.$route;

            if (route.name !== "event") {
              let params = {
                timeType: 2,
                language: this.currentLanguageObj.locale,
                lang: this.currentLanguageObj.apiParam,
              };

              const result = await this.getContentList(params);

              let returnResult = result.result;

              this.navigationMenuEvent(returnResult);
            }
          }

          let fullPath = window.location.pathname.split("/");
          if (this.$route.query) {
            if (this.$route.query.asInit === "init" && this.currentCountryObj) {
              fullPath[1] = this.currentCountryObj.supportedLocale[0];
              this.$router.replace(fullPath.join("/"));
            }
          }
          if (this.$route.redirectedFrom) {
            if (this.$route.redirectedFrom.path === "/"){   
              if (this.currentCountryObj) {
                if(this.currentCountryObj.supportedLocale.includes(countryCode.toLowerCase())) {
                    this.$router.replace(`/${this.currentCountryObj.supportedLocale[0]}/football/matches`)
                }
               }
            }
          }
          if (this.currentCountryObj) {
            if (!this.currentCountryObj.supportedLocale.includes(this.currentLocale)) {
                fullPath[1] = this.currentCountryObj.supportedLocale[0];
                this.$router.replace(fullPath.join("/") + window.location.search);
              }
          } else {
              fullPath[1] = config.defaultLocale;
              this.$router.replace(fullPath.join("/") + window.location.search);
          }
        
        } else {
          // let fullPath = window.location.pathname.split("/");
          
          // if (this.$route.query) {
          //   if (this.$route.query.asInit === "init" && this.currentCountryObj) {
          //     fullPath[1] = this.currentLanguageObj.displayLocale;
          //     this.$router.replace(fullPath.join("/"));
          //     // window.location.href = window.location.origin + fullPath.join("/");
          //   }
          // }
          // if (this.$route.name === "signin" && !this.$route.query) {
          //   this.$router.replace(`/${this.currentLocale}/matches`)
          // } else if (this.$route.name === "signin" && this.$route.query) {
          //   let path = this.$route.query.callBackUrl.split("/");
          //   path[1] = this.currentLanguageObj.displayLocale;
          //   this.$router.replace(path.join("/"));
          // }
          // if (this.currentCountryObj) {
          //   if (!this.currentCountryObj.supportedLocale.includes(this.currentLocale)) {
          //       fullPath[1] = this.currentCountryObj.supportedLocale[0];
          //       this.$router.replace(fullPath.join("/") + window.location.search);
          //     }
          // } else {
          //     fullPath[1] = config.defaultLocale;
          //     this.$router.replace(fullPath.join("/") + window.location.search);
          // }
        }
      },
      // AS-1432 - filter language object list based on user preferred country / visitor country
      async langObjListBasedOnCountry() {
        // if (localStorage.getItem(USER_LANG) === null) { // first time loading (non-logged in) / redirect from just logged out
          let locatedCountryCode = null;
          if (localStorage.getItem(USER) === null) {// && localStorage.getItem(USER_COUNTRY_CODE) === null
            // get user's current located country code
            let result = await this.getCountryCodeByIp();
            if (result.message === "Success") {
              locatedCountryCode = result.result.countryCode;//result.result.countryCode 'KH'
              // this.SET_USER_COUNTRY_CODE(locatedCountryCode);
            }
          }

          this.$nextTick(() => {
            if (localStorage.getItem(USER) !== null && localStorage.getItem(USER_LANG) === null) {
              let params = {
                countryCode: this.userInfo.preferredCountry,
                action: "No_Action",
              };
              this.buildCountryBasedLangConfigUi(params);
            }

            if (localStorage.getItem(USER) === null) {// && localStorage.getItem(USER_LANG) === null
              let params = {
                countryCode: locatedCountryCode,
                action: "No_Action",
              };
              this.buildCountryBasedLangConfigUi(params);
            }

            this.verifyUserLandOnPreferredLang();
          });
      },
      
    },
    mixins: [ResizeMixin],
  }

</script>

<style>

  @import url('https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.5.8/swiper-bundle.css');
  @import url('https://cdn.jsdelivr.net/npm/mc-datepicker/dist/mc-calendar.min.css');

  /* @import '../src/js/swipper.js'; */

#app {
  /* font-family: 'Open Sans', Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
</style>
