<template>
  <div class="fav-card__wrapper display-flex flex-col">
			
    <a
      target="_blank"
      class="fav-card__match-container pointer"
      v-if="type === 'COUNTRY' "
      @click="handleFavClick($event)"
    >
      <div class="fav-card__detail display-flex">
        <div class="display-flex-center">
          <Icon :category="'country'" :logoUrl="logo"></Icon>
        </div>
        <div class="fav-card__team">
          <div class="flag">{{ name }}</div>
        </div>
      </div>
      <div class="btn-fav-wrapper__search">
        <div v-if="type === 'COUNTRY'" class="btn-arrow-right"></div>
        <!-- <div v-else class="btn-fav" :class="isFav ? 'selected' : null" @click="handleFavClick($event)"></div> -->
      </div>
    </a>
    <a
      target="_blank"
      class="fav-card__match-container"
      v-else-if="type === 'sample'"
    >
      <div class="fav-card__detail display-flex">
        <div class="display-flex-center fav-flag">
          <img
            src="../../../../static/images/team_logo_default.png"
            alt="logo"
          />
        </div>
        <div class="fav-card__team">
          <div class="flag" v-if="type === 'sample'">{{ name }}</div>
        </div>
      </div>
      <!-- <div class="fav-star" :class="isFav ? 'selected' : null"></div> -->
      <!-- <div class="btn-fav-wrapper__search">
        <div class="btn-fav"></div>
      </div> -->
    </a>
    <a v-else-if="isDisableRedirect"  class="fav-card__match-container ">
        <div class="fav-card__detail display-flex">
            <div class="display-flex-center">
            <img
                v-if="!logo"
                src="../../../../static/images/icons/icon_flag_empty.png"
                alt="logo"
            />
            <Icon v-else :category="'country'" :logoUrl="logo"></Icon>
            </div>
            <div class="fav-card__team">
            <div class="flag">{{ name }}</div>
            <div class="flag" v-if="type === 'sample'">Country</div>
            </div>
        </div>
        <!-- <div class="fav-star" :class="isFav ? 'selected' : null"></div> -->
        <!-- <div class="btn-fav-wrapper__search" @click.prevent="handleFavClick($event)" >
          <div
          class="btn-fav"
          :class="isFav ? 'selected' : null"            
          ></div>
        </div> -->
        
    </a>
    <router-link
      :to="urlPath"
      @click="handleRedirect($event)"
      v-else
    >
      <div class="fav-card__match-container ">
        <div class="fav-card__detail display-flex">
          <div class="display-flex-center">
            <img
              v-if="!logo"
              src="../../../../static/images/icons/icon_flag_empty.png"
              alt="logo"
            />
            <Icon v-else :category="'country'" :logoUrl="logo"></Icon>
          </div>
          <div class="fav-card__team">
            <div class="flag">{{ name }}</div>
            <div class="flag" v-if="type === 'sample'">Country</div>
          </div>
        </div>
        <!-- <div class="fav-star" :class="isFav ? 'selected' : null"></div> -->
        <div class="btn-fav-wrapper__search" @click.stop.prevent="handleFavClick($event)" >
          <!-- <div
            class="btn-fav"
            :class="isFav ? 'selected' : null"
          ></div> -->
        </div>
      </div>
      
    </router-link>
  </div>
  	<PopUpSignInIndexMobile ref="PopUpSignInIndexMobileRef" :message="$i18n.t('FAV_LOGIN_TIPS')" ></PopUpSignInIndexMobile>

</template>

<script>
import { mapActions, mapGetters } from "vuex";

import PopUpSignInIndexMobile from "@/components/user/PopUpSignInIndexMobile.vue"
export default {
  props: ["id", "name", "logo", "isFav", "type", "titleType", "keyName","countryKey", "object", "isShowCompetionFromCountry", "isDisableRedirect"],
  data() {
    return {};
  },
  components: {
    PopUpSignInIndexMobile
  },
  computed: {
    ...mapGetters(["isLogin", "userInfo", "currentLocale", "currentSportType", "isMobile"]),
    urlPath() {
    if (this.isDisableRedirect) {
        return "/#"
    } else {
        if (this.type === "COMPETITIONS" || this.type === "COMPETITION") {
            return {
            path: `/${this.currentLocale}/${this.currentSportType}/${this.countryKey}/${this.keyName}`,
				query: {
					leagueId: this.id,
				},
            };
        } else {
            return {
            	path: `/${this.currentLocale}/${this.currentSportType}/team/${this.name.split(" ").join("-")}/${this.id}`,
            };
        }
    }

       
    },
  },
  methods: {
      ...mapActions([     
        "favLoginTipsChangeEvent"
      ]),
      handleFavClick(event) {
        if (this.isLogin) {
          this.$emit('favHandler', this.id, this.isFav, this.titleType, this.name, this.type, this.object)
        } else {

          if (this.titleType === 'COUNTRY' && !this.isShowCompetionFromCountry) {
            this.$emit('favHandler', this.id, this.isFav, this.titleType, this.name, this.type, this.object)
          } else {
            if (this.isMobile) {
              this.$refs.PopUpSignInIndexMobileRef.handleOpen();
            } else {
              this.favLoginTipsChangeEvent({isOpen: true, event: event}) 
            }
          }
       
        }
      },
      handleRedirect(event) {
        if (this.isDisableRedirect) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.$emit("pageIsRedirected");
      }

  },
};
</script>

<style scoped>
.fav-card__wrapper {
  width: 100%;
  gap: 0.5rem;
}
.fav-card__match-container:hover {
  background-color: var(--bg-hover);
}
.fav-card__match-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-theme);
  border-radius: 0.5rem;
  width: inherit;
  box-sizing: border-box;
  padding: 0rem 0 0rem 0.625rem;
  border-left: 0.4rem solid transparent;
  height: 3.125rem;
}
.fav-card__detail {
  align-items: center;
  gap: 0.6875rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.fav-card__team div:nth-child(1) {
  font-weight: 700;
}
.fav-card__team div:nth-child(2) {
  font-size: 0.625rem;
  line-height: 1rem;
  color: #93a9b4;
}
.fav-flag {
  width: 1.25rem;
  height: 1.25rem;
  object-fit: contain;
}
.fav-flag img {
  width: 100%;
  height: 100%;
}
.fav-star {
  width: 1.25rem;
  height: 1.25rem;
  background: url("../../../../static/images/icons/icon_star_white.png")
    no-repeat center center;
  background-size: contain;
}
.fav-star.selected {
  background: url("../../../../static/images/icons/icon_star_selected.png")
    no-repeat center center;
}
.live-selected {
  border-left: 0.5rem solid var(--bg-selected);
}
.btn-arrow-right {
  background: url("../../../../static/images/icon_arrow_right.png") no-repeat
    center center;
  width: 1rem;
  height: 1rem;
  background-size: contain;
}

@media (max-width: 768px) {
    .fav-card__match-container:hover {
        background-color: var(--color-theme);
    }
}
</style>
