<template>
  <div v-if="isLogin" class="display-flex-center">


    <div class="header-profile-pic hidden">
      <img :src="`${s3ImgUrl}/${userInfo.profilePicture}`"  class="w-100 h-100" @click="handleShowSettingList('click')"/>
    </div>
    <div class="relative">
      <!-- setting selection -->
      <div class="setting-list__wrapper" :class="{ selected: isShowSettingList }">
        <div
          class="setting-list__container"
          v-for="(o, index) in settingList"
          :key="index"
          @click="handleSetting(o)"
        >
          <div class="setting-list__name">
            <span>{{ $t(o.name) }}</span>
          </div>
          <div v-if="o.hasChild">
            <img src="../../../static/images/icons/icon_arrow_right_white.png"/>
          </div>
        </div>
      </div>

      <!-- country selection -->
      <transition name="slide">
        <div class="setting-list__wrapper selected" v-if="isShowCountryList">
          <div class="setting-list__container">
            <div class="setting-list__name" @click="handleCountryBack()">
              <div class="setting-list__name-icon flex-start">
                <img
                  class="img-contain"
                  src="../../../static/images/icons/icon_arrow_left_white.png"
                />
              </div>
              <span>{{ $t("SELECT_COUNTRY") }}</span>
            </div>
          </div>

          <div
            class="setting-list__container"
            v-for="(o, index) in countryList"
            :key="index"
            :class="{ selected: o.selected }"
            @click="countrySelectItem(o)"
          >
            <div class="setting-list__name">
              <div class="setting-list__name-icon">
                <img
                  class="img-contain "
                  :src="require(`../../../static/images/flags/${o.icon}.png`)"
                />
              </div>
              <span>{{ o.name }}</span>
            </div>
            <div v-if="o.selected">
              <img src="../../../static/images/icons/icon_tick_selected.png" />
            </div>
          </div>
        </div>
      </transition>

      <!--currentLanguageList-->
      <!-- langauge selection -->
      <transition name="slide">
        <div class="setting-list__wrapper selected" v-if="isShowLanguageList">
          <div class="setting-list__container">
            <div class="setting-list__name" @click="handleLanguageBack()">
              <div class="setting-list__name-icon flex-start">
                <img
                  class="img-contain"
                  src="../../../static/images/icons/icon_arrow_left_white.png"
                />
              </div>
              <span>{{ $t("LANGUAGE") }}</span>
            </div>
          </div>

          <div
            class="setting-list__container"
            v-for="(o, index) in userLangList"
            :key="index"
            :class="{ selected: o.selected }"
            @click="languageSelectItem(o)"
          >
            <div class="setting-list__name">
              <!-- <div class="setting-list__name-icon">
                <img
                  class="img-contain "
                  :src="require(`../../../static/images/flags/${o.icon}.png`)"
                />
              </div> -->
              <span>{{ o.displayName }}</span>
            </div>
            <div v-if="o.selected">
              <img src="../../../static/images/icons/icon_tick_selected.png" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <!-- Login Signup -->
  <div v-else class="nav-pill__wrapper pb-0">
    <!-- App Redirect Hover -->
    <!-- <HeaderAppBanner v-show="!isMobile" /> -->
    <router-link :to="{ name: 'signin' }" class="nav-pill">{{ $t("LOGIN") }}</router-link>
    <router-link :to="{ name: 'signup' }" class="nav-pill selected">{{ $t("SIGNUP") }}</router-link>
  </div>
</template>

<script>
import config from "@/js/config.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import HeaderAppBanner from "../modal/HeaderAppBanner.vue";

export default {
  data() {
    return {
      settingList: [
        {
          name: "PROFILE_SETTING",
          id: "profileSetting",
          hasChild: false,
        },
        {
          name: "COUNTRY",
          id: "country",
          hasChild: true,
        },
        {
          name: "LANGUAGE",
          id: "language",
          hasChild: true,
        },
        {
          name: "LOG_OUT",
          id: "logout",
          hasChild: false,
        },
      ],
      s3ImgUrl: config.s3ImgUrl,
      fantasySportLogoutUrl: config.fantasySportLogoutUrl,
      isShowSettingList: false,
      isShowCountryList: false,
      isShowLanguageList: false,
      redirectShow: false,

      userLangList: [],
      countryList: [],
      apiCallDone: true,
    };
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "isLogin",
      "userInfo",
      "currentLocale",
      "currentLanguageList",
      "currentCountryList",
      // "userLangConfig",
      "userSelectedLangConfigObj",
      "currentCountryObj",
      "currentLanguageObj",
    ]),
  },
  watch: {
    isLogin: {
      handler(newVal, oldval) {
        this.init();
      },
    }
  },
	mounted() {
    this.init();
	},
	methods: {
    ...mapMutations([
      "USER_SWITCH_COUNTRY_LANG",
      "COUNTRY_CODE_TO_LANG_OBJ_LIST",
      "UPDATE_USER_LANG_CONFIG",
      "REMOVE_USER_LANG_CONFIG",
    ]),
		...mapActions([
			"postLogout",
			"logoutEvent",
			"currentLocaleChangeEvent",
			"postUpdateProfilePreferredLanguage",
			"getRetrieveUserInfo",
			"setUserData",
			"toastrChangeEvent",
      "currentCountryChangeEvent",
      "postUpdateProfilePreferredCountry",
      "getCountryCodeByIp",
      "commonVerifyLogin",
      "currentCountryLocaleChangeEvent",
      "toggleCountryAndLanguage"
		]),

    init() {
      this.commonVerifyLogin();
      // this.countryList = this.currentCountryList;
      if (this.isLogin) {
        this.initCountryList();
        this.setLangList(this.userInfo.preferredCountry);
      }
    },

    initCountryList() {
      this.countryList = this.currentCountryList;
      this.countryList.forEach(x => {
        x.selected = (x.countryCode === this.userInfo.preferredCountry) ? true : false;
      });
    },

    setCountryList(o) {
      this.countryList = this.currentCountryList;
      this.countryList.forEach(x => {
        x.selected = (x.countryCode === o.countryCode) ? true : false;
      });
    },

    async setLangList(countryCode) {
      let supportedLocaleList = this.currentCountryList.filter(x => x.countryCode === countryCode)[0].supportedLocale;
      this.userLangList = [];

      if (!this.isLogin) {
        let result = await this.getCountryCodeByIp();
				if (result.message === "Success") {
          this.currentCountryChangeEvent(result.result.countryCode);
				}
      }

      supportedLocaleList.forEach(locale => {
        this.currentLanguageList.forEach(obj => {
          if (obj.displayLocale === locale) {
            obj.selected = (obj.displayLocale === this.currentLocale) ? true : false;
            this.userLangList.push(obj);
          }
        });
      });
    },

		handleShowSettingList(action) {
		if (this.isMobile) {
			this.$router.push({ name: "account" });
		} else {
			if (action === "enter" && !this.isMobile) {
			//ingnore mobile touch event
			this.isShowSettingList = true;
			} else if (action === "click") {
        if (this.isShowLanguageList || this.isShowCountryList) {
          this.isShowSettingList = false;
          this.isShowCountryList = false;
          this.isShowLanguageList = false;
        } else {
          this.isShowSettingList = !this.isShowSettingList;
          this.isShowCountryList = false;
          this.isShowLanguageList = false;
        }
			} else {
			this.isShowSettingList = false;
      this.isShowCountryList = false;
			this.isShowLanguageList = false;
			}
		}
		},
		handleSetting(o) {
		switch (o.id) {
			case "profileSetting":
			this.handleProfileSetting();
			this.isShowSettingList = false;
			break;
      case "country": 
        this.handleCountrySelect();
        this.isShowCountryList = true;
      break;
			case "language":
			this.handleLanguageSelect();
			break;
			case "logout":
			this.handleLogout();
			this.isShowSettingList = false;
			break;
		}
		},
    handleCountrySelect() {
      this.isShowSettingList = false;
      this.isShowCountryList = true;
    },
		handleLanguageSelect() {
		this.isShowSettingList = false;
		this.isShowLanguageList = true;
		},
		//handle logout
		async handleLogout() {
			let deviceTokenMotification = localStorage.getItem("deviceTokenNotification")
			let params = {
				token: this.userInfo.token,
			};
			if (deviceTokenMotification) {
				params.deviceToken = deviceTokenMotification
			}

			const result = await this.postLogout(params);

			if (this.$route.meta.parent === "account") {
				this.$router.push(`/${this.currentLocale}`);
			}
		
			this.logoutEvent();

      //logout fantasy
      let fantasyHtml = $(`<iframe class="fantasy-iframe-logout" onload="$('.fantasy-iframe-logout').remove()" src="${this.fantasySportLogoutUrl}?logout=true"></iframe>`);
      $("body").append(fantasyHtml);

      this.REMOVE_USER_LANG_CONFIG();
		},
		//handle profile setting
		handleProfileSetting() {
		this.$router.push({ name: "myProfile" });
		},
    handleCountryBack() {
			this.isShowSettingList = true;
			this.isShowCountryList = false;
		},
		handleLanguageBack() {
			this.isShowSettingList = true;
			this.isShowLanguageList = false;
		},
		// get user info
		async getRetrieveUserInfoData() {

			let params = {
				token: this.userInfo.token,
				timeZone: this.$tools.getCurrentTimeZone(),
			};

			const result = await this.getRetrieveUserInfo(params);
			this.setUserData(result.result.data);
		},

    async countrySelectItem(o) {
      if (this.isLogin && this.apiCallDone) {
        this.apiCallDone = false;
        // this.currentCountryChangeEvent(o.countryCode);
        this.setCountryList(o);
        this.setLangList(o.countryCode);
        this.userLangList.forEach((lang, i) => {
          lang.selected = (i === 0) ? true : false;
        });
        let defaultCountryLangObj = this.userLangList[0];

        let params = {
          preferredCountry: o.countryCode,
        };

        const result = await this.postUpdateProfilePreferredCountry(params);
        this.apiCallDone = true;

        if (result.result.businessCode === 0) {
          // this.getRetrieveUserInfoData();
          let localStorageUser = JSON.parse(localStorage.getItem("user"));
          localStorageUser.preferredCountry = o.countryCode;
          this.setUserData(localStorageUser);
          this.languageSelectItem(defaultCountryLangObj, o.countryCode);
          
        } else {
          let params = {
            isOpen:true,
            msg:result.result.message,
            type:'info'       
          }
          this.toastrChangeEvent(params);
        }
      }
    },

		async languageSelectItem(o, countryCode = null) {
      if (this.apiCallDone) {
        this.apiCallDone = false;
        let theCountryCode = (countryCode === null) ? this.userInfo.preferredCountry : countryCode;
        
        let params = {
          preferredLanguage: o.locale,
          token: this.userInfo.token,
        }; 
        const result = await this.postUpdateProfilePreferredLanguage(params);
        this.apiCallDone = true;
        
        if (result.result.businessCode === 0) {
          // this.getRetrieveUserInfoData();
          let localStorageUser = JSON.parse(localStorage.getItem("user"));
          localStorageUser.preferredLanguage = o.locale;
          this.setUserData(localStorageUser);
          this.currentCountryLocaleChangeEvent({
            countryCode: theCountryCode,
            locale: o.locale,
          });

          this.USER_SWITCH_COUNTRY_LANG();
          console.log("setting", this.currentLanguageObj.apiParam)

          //this.currentLocaleChangeEvent(o.displayLocale);//currentLanguageObj 
          console.log("setting2", this.currentLanguageObj.apiParam)

        } else {
          let params = {
            isOpen: true,
            msg: result.result.message,
            type: "info",
          };
          this.toastrChangeEvent(params);

          console.log(this.currentLocale);
          // this.$root.toastrMsg = result.result.message;
          // this.$root.$refs.toastrRef.handleOpen();
        }

        this.toggleCountryAndLanguage();
        this.UPDATE_USER_LANG_CONFIG(o.displayLocale);
      }
    }
	},

  components: { HeaderAppBanner },
};
</script>

<style>
.setting-list__wrapper {
  position: absolute;
  right: 0;
  margin-top: 2rem;
  min-width: 12rem;
  background-color: var(--color-grey-60);
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  transform: scale(0);
  transform-origin: 11.5rem 0%;
  transition: all 0.3s;
}
.setting-list__wrapper.selected {
  /* transform-origin: 11.5rem 0%; */
  transform: scale(1);
}
.setting-list__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  transition: all 0.3s;
}
.setting-list__container.selected,
.setting-list__container:hover {
  background-color: var(--color-grey-40);
}
.setting-list__name {
  display: flex;
  align-items: center;
  font-size: 0.88rem;
}
.setting-list__name-icon {
  object-fit: contain;
  margin-right: 0.7rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
