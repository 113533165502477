import config from "@/js/config.js";
import footerLinks from "@/js/seoArticles/footer.js";

const projectName = config.projectName;
const description = `AsiaSport is a current hub where all the sports fans gather for newsfeed. Every aspect of the tournament will be made accessible at all times. When a football match is held, the live scores will be updated in real-time as it progresses.`
const footerLinksPageMeta = {};
Object.values(footerLinks).forEach(arr => {
    if (arr.length > 0) {
        arr.forEach(article => {
            footerLinksPageMeta[article.id] = (route) => ({
                title: article.titleTag,
                description: article.metaDescription,
            });
        });
    }
});

export default {
    'home': (route) => (
    {
        title: `ផ្សាយផ្ទាល់លទ្ធផលបាល់ទាត់ ពិន្ទុ តារាងការប្រកួត សកម្មភាពហាយឡាយ ពី  - ${projectName}`,
        description: description
    }),
    'highlights': (route) => ({
        title: `បាល់ទាត់ Football Highlights, Livescore, Videos - ${projectName}`,
        description: description
    }),
    'news': (route) => ({
        title: `ព័ត៌មានកីឡាបាល់ទាត់, English Premier League News - ${projectName}`,
        description: description
    }),
    'event': (route) => ({
        title: `ការប្រកួតបាល់ទាត់, Football Contests & Events - ${projectName}`,
        description: description
    }),
    'account': (route) => ({
        title: `My Profile - ${projectName}`,
        description: description
    }),
    'player': (route) => ({
        title: `${route.params.playerName
            .split("-")
            .join(" ")} Profile - ${projectName}`,
        description: description
    }),
    'matchDetail': (route) => ({
       title: `${route.params.opponent
            .split("-")
            .join(" ")} វីដេអូហាយឡាយs - ${projectName}`,
        description: description
    }),
    'highlightsDetail': (route) => ({
        title: `${route.params.opponent
             .split("-")
             .join(" ")} វីដេអូហាយឡាយ - ${projectName}`,
         description: description
     }),
    ...footerLinksPageMeta,
};