import config from "@/js/config.js";
import footerLinks from "@/js/seoArticles/footer.js";

const projectName = config.projectName;
const description = `AsiaSport is a current hub where all the sports fans gather for newsfeed. Every aspect of the tournament will be made accessible at all times. When a football match is held, the live scores will be updated in real-time as it progresses.`
const footerLinksPageMeta = {};
Object.values(footerLinks).forEach(arr => {
    if (arr.length > 0) {
        arr.forEach(article => {
            footerLinksPageMeta[article.id] = (route) => ({
                title: article.titleTag,
                description: article.metaDescription,
            });
        });
    }
});

export default {
    'home': (route) => (
    {
        title: `Kết quả bóng đá, tỷ số trực tiếp, lịch thi đấu, video bóng đá - ${projectName}`,
        description: description
    }),
    'highlights': (route) => ({
        title: `Trực tiếp boóng đá hôm nay, Xem bong da truc tiêp, kết quả - ${projectName}`,
        description: description
    }),
    'news': (route) => ({
        title: `Tin tức bóng đá việt nam, Ngoại hạng anh, Bongdalive - ${projectName}`,
        description: description
    }),
    'event': (route) => ({
        title: `Chương trình bóng đá, bóng đá trực tuyến - ${projectName}`,
        description: description
    }),
    'account': (route) => ({
        title: `My Profile - ${projectName}`,
        description: description
    }),
    'player': (route) => ({
        title: `${route.params.playerName
            .split("-")
            .join(" ")} Hồ sơ - ${projectName}`,
        description: description
    }),
    'matchDetail': (route) => ({
       title: `${route.params.opponent
            .split("-")
            .join(" ")} Video Điểm Nhấn Trận Đấu - ${projectName}`,
        description: description
    }),
    'highlightsDetail': (route) => ({
        title: `${route.params.opponent
             .split("-")
             .join(" ")} Video Điểm Nhấn Trận Đấu - ${projectName}`,
         description: description
     }),
    ...footerLinksPageMeta,
};